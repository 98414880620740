import React, { useCallback, useEffect, useState } from "react";
import { ContentBlockShape } from "@/models";
import { useUpdateStyles } from "../../content-block-management/useSaveContentBlock";
import { debounce, useGotContentBlock } from "@/hooks";
import { useContentBlockState } from "../../useContentBlockState";
import { useUpdateActiveContentBlock } from "@/hooks/activeContentBlock";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTrigger,
} from "@/components/Dialog";
import { PlayIcon } from "lucide-react";

type Props = { contentBlock: ContentBlockShape };

const animationScrollClasses = ["timeline-view", "range-on-entry-normal"];

const animationClasses = {
    "Fade in": "animate-fade-in",
    "Fade up and in": "animate-appear-up-and-in",
    "Fade down and in": "animate-appear-down-and-in",
    "Fade left and in": "animate-appear-left-and-in",
    "Fade right and in": "animate-appear-right-and-in",
    "Appear from sides": "appear-from-sides",
    "Shrink and appear": "animate-shrink-and-appear",
};

export function AnimationMenu({ contentBlock }: Props) {
    const { mutateAsync, isPending } = useUpdateStyles();
    const { setContentBlock } = useContentBlockState();
    const { gotContentBlock } = useGotContentBlock();
    const { updateActiveContentBlock } = useUpdateActiveContentBlock();

    const currentAnimationClass = contentBlock?.theme?.animationClasses?.find(
        (className) => Object.values(animationClasses).includes(className),
    );

    const [selectedAnimation, setSelectedAnimation] = useState(
        currentAnimationClass,
    );

    const handleChange = useCallback(
        async (animationClassName: string) => {
            const newBlock = {
                ...contentBlock,
                theme: {
                    ...contentBlock.theme,
                    animationClasses: [
                        animationClassName,
                        ...animationScrollClasses,
                    ],
                },
            };
            setContentBlock(newBlock);
            await mutateAsync(newBlock);
            updateActiveContentBlock(newBlock);
            gotContentBlock(newBlock);
            setIsOpen(false);
        },
        [selectedAnimation],
    );

    const [isOpen, setIsOpen] = useState(false);
    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger
                className="hover:animate-wiggle rounded-md bg-white p-1 text-black transition-all
                    duration-300 hover:bg-slate-200 hover:scale-105 shadow-md"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1200pt"
                    height="1200pt"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    className="h-6 w-6"
                >
                    <path d="m635.26 517.74c10.641 12.703 8.9062 31.641-3.7969 42.281-92.766 77.531-136.97 160.64-157.74 216.74-22.547 61.031-23.156 104.34-23.156 104.77-0.14062 16.359-13.312 29.672-29.672 29.812h-0.32812c-16.219 0-29.531-12.844-30-29.062-6-208.97-62.906-343.31-109.55-419.21-50.297-81.844-99.844-115.17-100.31-115.5-13.781-9.0938-17.672-27.656-8.625-41.484 9.0469-13.781 27.469-17.766 41.344-8.7656 7.875 5.1094 168.24 112.59 220.97 417.66 29.156-61.875 77.719-133.5 158.53-201 12.75-10.594 31.641-8.9062 42.281 3.7969zm397.5-60.844c0 92.766-75.469 168.28-168.28 168.28-92.766 0-168.28-75.469-168.28-168.28 0-92.766 75.469-168.28 168.28-168.28 92.766 0 168.28 75.469 168.28 168.28zm-60 0c0-59.719-48.562-108.28-108.28-108.28s-108.28 48.562-108.28 108.28 48.562 108.28 108.28 108.28 108.28-48.562 108.28-108.28z" />
                </svg>
            </DialogTrigger>
            <DialogContent
                className="e-learning ml-4 mt-2 max-h-[40rem] w-full max-w-4xl overflow-y-auto rounded-md
                    bg-white p-2 py-2 view-timeline/animation-menu"
            >
                <div className="flex flex-col gap-4">
                    <h3 className="text-lg font-bold">Choose an animation</h3>
                    <div className="flex flex-wrap gap-4 p-4">
                        {Object.entries(animationClasses).map(
                            ([title, animationClassName]) => (
                                <AnimationButton
                                    key={title}
                                    title={title}
                                    animationClassName={animationClassName}
                                    update={handleChange}
                                    isSelected={
                                        animationClassName === selectedAnimation
                                    }
                                    setSelectedAnimation={setSelectedAnimation}
                                />
                            ),
                        )}
                    </div>
                </div>
                <DialogFooter className="flex justify-end px-10 py-4">
                    <button
                        onClick={() => {
                            handleChange(selectedAnimation);
                        }}
                        className="rounded-md bg-indigo-500 px-10 py-4 text-white disabled:animate-pulse
                            disabled:opacity-50"
                        disabled={isPending || !selectedAnimation}
                    >
                        Apply
                    </button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

function AnimationButton({
    title,
    animationClassName,
    update,
    isSelected,
    setSelectedAnimation,
}: {
    title: string;
    animationClassName: string;
    update: (animationClassName: string) => void;
    isSelected: boolean;
    setSelectedAnimation: (animationClassName: string) => void;
}) {
    const [isRunning, setIsRunning] = useState(false);
    const [playButtonIsHidden, setPlayButtonIsHidden] = useState(true);

    useEffect(() => {
        const playButtonIsHiddenTimeout = setTimeout(() => {
            setPlayButtonIsHidden(false);
        }, 3000);
        const timeout = setTimeout(() => {
            setIsRunning(() => true);
        }, 2500);
        return () => {
            clearTimeout(timeout);
            clearTimeout(playButtonIsHiddenTimeout);
        };
    }, []);

    let timeout: NodeJS.Timeout;
    useEffect(() => {
        if (isRunning) {
            timeout = setTimeout(() => {
                setIsRunning(false);
            }, 2800);
        }
        return () => clearTimeout(timeout);
    }, [isRunning]);

    return (
        <div className={"relative w-[calc(33%-1rem)] max-w-xl"}>
            <button
                className="group/play absolute right-0 top-0 z-2 flex items-center justify-center
                    rounded-full bg-white p-2 text-indigo-500 opacity-75 shadow-md transition-all
                    delay-200 duration-300 -translate-y-1/2 translate-x-1/2
                    data-[is-hidden=true]:opacity-0 hover:text-indigo-600 hover:opacity-100
                    disabled:animate-pulse disabled:opacity-50"
                disabled={isRunning}
                data-is-hidden={playButtonIsHidden}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsRunning(() => false);
                    setIsRunning(() => true);
                }}
            >
                <PlayIcon className="h-5 w-5 transition-all duration-300 group-hover/play:scale-110" />
            </button>
            <button
                className="group/wrapper flex w-full flex-col items-center gap-2 overflow-hidden
                    rounded-2xl border-2 border-transparent transition-all duration-300
                    data-[is-selected=true]:border-indigo-500"
                onClick={() => {
                    setSelectedAnimation(animationClassName);
                }}
                data-is-selected={isSelected}
            >
                <div className={"flex w-full flex-col items-center gap-2 "}>
                    <div
                        className={`animation-time animation-delay flex h-48 w-full flex-col items-center
                        justify-center gap-2 rounded-2xl bg-gradient-to-br from-sky-200 to-indigo-200
                        px-6 py-4 font-medium text-indigo-800 shadow-xl transition-all duration-300
                        group-hover/wrapper:scale-105 hover:from-sky-200 hover:to-indigo-300 ${
                            isRunning ? animationClassName : ""
                        }`}
                        style={
                            {
                                "--animation-duration": "2s",
                                "--animation-delay": "0s",
                            } as React.CSSProperties
                        }
                    >
                        <div
                            style={
                                {
                                    "--animation-duration": "2s",
                                    "--animation-delay": "0s",
                                } as React.CSSProperties
                            }
                            className="animation-time animation-delay h-16 w-full rounded-md bg-gradient-to-br
                                from-white to-indigo-50"
                            data-content-block-id
                        ></div>
                        <div
                            style={
                                {
                                    "--animation-duration": "2s",
                                    "--animation-delay": "0s",
                                } as React.CSSProperties
                            }
                            className="animation-time animation-delay flex w-full items-center justify-center gap-2
                                rounded-md bg-gradient-to-br from-indigo-50 to-indigo-100 p-4 text-sm font-bold"
                            data-content-block-id
                        >
                            {title}
                        </div>
                        <div
                            style={
                                {
                                    "--animation-duration": "2s",
                                    "--animation-delay": "0s",
                                } as React.CSSProperties
                            }
                            className="animation-time animation-delay h-16 w-full rounded-md bg-gradient-to-br
                                from-indigo-100 to-indigo-200"
                            data-content-block-id
                        ></div>
                    </div>
                </div>
            </button>
        </div>
    );
}
