import { ContentBlockShape, RichText } from "@/models";
import { Editor, EditorContent } from "@tiptap/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDebouncedEditor } from "@/modules/text-editor/useDebouncedEditor";
import { RichTextTooltipMenu } from "@/components/rich-text";
import { useSaveContentBlock } from "../../Design/content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../Design/useContentBlockState";
import { useRichTextModelQuantities } from "./useRichTextModelQuantities";
import { cn } from "@/util";
import { themeToStyleVariables } from "@/tailwind-colors/themePropertyToStyleVariable";
import { useUpdateOption } from "../../Design/content-block-management/useSaveOption";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import Menu from "@/components/rich-text/Menu";
import { createPortal } from "react-dom";
import { useSaveRichText } from "../../Design/content-block-management/useSaveRichText";

type Props = {
    editable: boolean;
    content: RichText;
    contentBlock: ContentBlockShape;
    saveOverride?: (rich_text: RichText | App.Data.RichTextData) => void;
    delayMilliseconds?: number;
};

const isTextSelected = (editor: Editor) => {
    if (!editor) return false;
    const { from, to } = editor.state.selection;
    return from !== to;
};

const RichTextBlock = React.memo(function RichTextBlock({
    editable = false,
    content,
    contentBlock,
    saveOverride,
    delayMilliseconds = 500,
}: Props) {
    const { mutateAsync } = useSaveRichText();
    const { setContentBlock } = useContentBlockState();
    const { mutateAsync: updateOption } = useUpdateOption();

    const handleSave = useCallback(
        (rich_text: RichText | App.Data.RichTextData, text?: string) => {
            mutateAsync({
                content_block_id: contentBlock.id,
                rich_text: rich_text as RichText,
            });
        },
        [contentBlock?.id, editable],
    );

    const editor = useDebouncedEditor({
        richText: content,
        isEditable: editable,
        updateOverride: saveOverride || handleSave,
        editedId: undefined,
        waitMilliseconds: delayMilliseconds,
        placeholder: editable ? "Add some text..." : "",
        onBlur: async (rich_text) => {
            console.log("blur", editor.getText());
            setContentBlock({
                ...contentBlock,
                rich_text: rich_text as RichText,
            });
            mutateAsync({
                content_block_id: contentBlock.id,
                rich_text: rich_text as RichText,
            });
            if (contentBlock.content_block_type === "OPTION") {
                updateOption({
                    content: editor.getText(),
                    content_block_id: contentBlock.id,
                });
            }
        },
    });

    const styleVariables =
        contentBlock.content_block_type === "RICH_TEXT"
            ? themeToStyleVariables(contentBlock.theme)
            : {};

    const { isLoading } = useRichTextModelQuantities();

    const element = useGetElementAsync(
        `[data-content-block-id="${contentBlock.id}"]`,
    );

    const showMenuInBubble = useMemo(() => {
        return element?.getBoundingClientRect().width > 400;
    }, [element]);

    return (
        <span
            data-content-block-id={contentBlock.id}
            data-parent-content-block-id={
                contentBlock.content_block_type === "RICH_TEXT"
                    ? contentBlock.parent_content_block_id
                    : undefined
            }
            data-is-loading={isLoading}
            className={`${cn(
                contentBlock.content_block_type === "RICH_TEXT"
                    ? contentBlock.theme?.tailwindClasses || []
                    : [],
            )} xs:max-w-2xl group
            data-[is-option=true]:w-full sm:max-w-3xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl`}
            style={styleVariables}
            data-is-option={contentBlock.content_block_type === "OPTION"}
        >
            {!!editor && editable && showMenuInBubble && (
                <RichTextTooltipMenu
                    editor={editor}
                    showColorPicker={true}
                    isOpen={true}
                    contentBlockId={contentBlock.id}
                />
            )}

            {!!editor &&
                editable &&
                !showMenuInBubble &&
                isTextSelected(editor) &&
                createPortal(
                    <div
                        className="tippy left-nav-popover-tiptap fixed left-2 top-2 z-[99999999]
                            data-[is-open=false]:opacity-0"
                        data-is-open={isTextSelected(editor)}
                    >
                        <Menu
                            editor={editor}
                            showColorPicker={true}
                            contentBlockId={contentBlock.id}
                        />
                    </div>,
                    document.body,
                )}

            <ModelQuantity />

            <EditorContent
                data-testid="editor-content"
                editor={editor}
                className="w-full"
            />
        </span>
    );
});

function ModelQuantity() {
    const {
        modelValues: values,
        isLoading,
        elements,
    } = useRichTextModelQuantities();

    useEffect(() => {
        elements?.forEach((element) => {
            element.classList.add("animate-pulse");
            element.textContent = "Loading...";
            const key = !!element.getAttribute("data-time-horizon-id")
                ? element.getAttribute("data-model-variable-id") +
                  "_" +
                  element.getAttribute("data-time-horizon-id")
                : element.getAttribute("data-model-variable-id");

            const modelValue = values?.[key];

            if (
                !isLoading &&
                modelValue &&
                element.textContent !== modelValue
            ) {
                element.textContent = modelValue;
                element.classList.remove("animate-pulse");
            }
        });
    }, [isLoading, values, elements]);

    return <></>;
}

export default RichTextBlock;
