import { useCallback, useMemo, useState } from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useAppSelector } from "@/redux-state";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { useModalQueryParams } from "@/hooks/useModalQueryParams/useModalQueryParams";
import { useAuthStore } from "./store";
import { atom, useAtom } from "jotai";

export const directionAtom = atom<"forward" | "backward">("forward");
directionAtom.debugLabel = "directionAtom";

export const useContentBlockBehaviors = (
    contentBlockId: string,
    isInAdminContext: boolean = false,
) => {
    const [direction, setDirection] = useAtom(directionAtom);
    const { openModal } = useModalQueryParams();

    const contentBlocks = useAppSelector((state) => {
        if (
            Object.keys(state?.participantContentBlockStore?.contentBlocks)
                ?.length
        ) {
            return state.participantContentBlockStore.contentBlocks;
        } else if (state?.contentBlockStore?.contentBlocks) {
            return state.contentBlockStore.contentBlocks;
        }

        return state?.contentBlockStore?.contentBlocks || "";
    });

    const { team } = useAuthStore();

    const behaviorContentBlock: ContentBlockShape = useMemo(() => {
        return contentBlocks[contentBlockId];
    }, [contentBlocks, contentBlockId]);

    const isLinkWithBehaviors = useMemo(() => {
        return (
            (behaviorContentBlock?.content_block_type ===
                ContentBlockType.Link ||
                behaviorContentBlock?.content_block_type ===
                    ContentBlockType["Inbox Notification"]) &&
            behaviorContentBlock?.behaviors?.click
        );
    }, [behaviorContentBlock]);

    const method = useMemo(() => {
        return isLinkWithBehaviors ? "get" : "post";
    }, [isLinkWithBehaviors]);

    const linkUrl = useMemo(() => {
        if (behaviorContentBlock?.behaviors?.click && isLinkWithBehaviors) {
            const {
                NavigateToPage,
                NavigateToNextPage,
                NavigateToPreviousPage,
                ExternalLink,
            } = behaviorContentBlock.behaviors.click;

            if (NavigateToPreviousPage) {
                setDirection("backward");
                return sapienRoute("sim-content.round_id.navigation.previous", {
                    linkId: behaviorContentBlock.id,
                });
            }

            if (NavigateToNextPage) {
                setDirection("forward");
                return sapienRoute("sim-content.round_id.navigation.next", {
                    linkId: behaviorContentBlock.id,
                });
            }

            if (NavigateToPage?.pageId) {
                setDirection("forward");
                return sapienRoute("sim-content.page_id.navigation", {
                    pageId: NavigateToPage.pageId,
                });
            }

            if (ExternalLink) {
                return ExternalLink;
            }
        }
    }, [behaviorContentBlock, contentBlocks, team, isLinkWithBehaviors]);

    const [isProcessing, setIsProcessing] = useState(false);

    const clickFunction = useCallback(
        (e?: React.MouseEvent | React.KeyboardEvent) => {
            const intertiaOptions = {
                onStart: () => {
                    setIsProcessing(() => true);
                },
                onFinish: () => {
                    setIsProcessing(() => false);
                },
            };

            // if (e) {
            //     e.preventDefault();
            // }
            if (behaviorContentBlock?.behaviors?.click) {
                const {
                    MoveToRound,
                    MoveToNextRound,
                    NavigateToPage,
                    NavigateToNextPage,
                    NavigateToPreviousPage,
                    ShowModalContentBlock,
                } = behaviorContentBlock.behaviors.click;

                if (MoveToRound && team) {
                    return SapienInertia.post(
                        "participant.advance",
                        {
                            team_id: team.id,
                            target_round_id: MoveToRound.roundId,
                        },
                        {},
                        intertiaOptions,
                    );
                }

                if (MoveToNextRound && team) {
                    return SapienInertia.post(
                        "participant.advance",
                        {
                            team_id: team.id,
                            current_round_id: behaviorContentBlock.round_id,
                        },
                        {},
                        intertiaOptions,
                    );
                }

                if (NavigateToPreviousPage) {
                    const route = sapienRoute(
                        "sim-content.round_id.navigation.previous",
                        {
                            linkId: behaviorContentBlock.id,
                        },
                    );
                    return SapienInertia.visit(route, intertiaOptions);
                }

                if (NavigateToNextPage) {
                    const route = sapienRoute(
                        "sim-content.round_id.navigation.next",
                        {
                            linkId: behaviorContentBlock.id,
                        },
                    );
                    return SapienInertia.visit(route, intertiaOptions);
                }

                if (NavigateToPage) {
                    return SapienInertia.visit(
                        sapienRoute("sim-content.page_id.navigation", {
                            pageId: NavigateToPage.pageId,
                        }),
                        intertiaOptions,
                    );
                }

                if (ShowModalContentBlock && !isInAdminContext) {
                    openModal({
                        modalComponent: "ContentBlockModal",
                        modalContentBlockId:
                            ShowModalContentBlock.modalContentBlockId ||
                            behaviorContentBlock.id,
                    });
                }
            }
        },
        [
            behaviorContentBlock,
            contentBlocks,
            team,
            isLinkWithBehaviors,
            isInAdminContext,
        ],
    );

    const behaviors = useMemo(() => {
        return behaviorContentBlock?.behaviors;
    }, [behaviorContentBlock, contentBlocks, contentBlockId]);

    return {
        clickFunction,
        isLinkWithBehaviors,
        method,
        linkUrl,
        behaviors,
        isProcessing,
    };
};
