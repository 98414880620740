
const propertyMap = {
    backgroundColor: "--bg-color",
    hoverBackgroundColor: "--bg-hover-color",
    activeBackgroundColor: "--bg-active-color",
    disabledBackgroundColor: "--bg-disabled-color",
    focusVisibleBackgroundColor: "--bg-focus-visible-color",
    focusWithinBackgroundColor: "--bg-focus-within-color",
    focusWithinActiveBackgroundColor: "--bg-focus-within-active-color",
    textColor: "--text-color",
    color: "--text-color",
    hoverTextColor: "--text-hover-color",
    hoverColor: "--text-hover-color",
    activeTextColor: "--text-active-color",
    disabledTextColor: "--text-disabled-color",
    focusVisibleTextColor: "--text-focus-visible-color",
    focusWithinTextColor: "--text-focus-within-color",
    focusWithinActiveTextColor: "--text-focus-within-active-color",
    borderColor: "--border-color",
    hoverBorderColor: "--border-color-hover",
    activeBorderColor: "--border-color-active",
    disabledBorderColor: "--border-color-disabled",
    focusVisibleBorderColor: "--border-color-focus-visible",
    focusWithinBorderColor: "--border-color-focus-within",
    focusWithinActiveBorderColor: "--border-color-focus-within-active",
    'to-color': "--to-color",
    'from-color': "--from-color",
    'via-color': "--via-color",
};


export const themeToStyleVariables = (theme: any) => {
    const variables = {};
    for (const property in theme) {
        if (propertyMap[property]) {
            variables[propertyMap[property]] = theme[property];
        }
    }
    return variables;
};

