import { useState, useEffect, useCallback } from "react";
import {
    ContentBlock,
    ContentBlockType,
    Prompt,
    PromptShape,
    Selection,
    SelectionShape,
} from "@/models";
import {
    FormContentBlockDefaultValuesObject,
    FormValidationSchema,
} from "@/styles";
import { groupBy, isEqual } from "lodash";
import {
    getSelectionData,
    getPromptsFromContentBlocks,
    getFormValidationSchema,
    getQuestionData,
} from "@/util";
import { mapById, useAppDispatch, useAppSelector } from "@/redux-state";
import {
    gotFormData,
    gotPrompts,
    gotFormContentBlocks,
    gotFormValidationSchemaMap,
    gotPromptsByForm,
    gotSelectionsByForm,
    gotIdsForSavingSelections,
    // gotControlledFormFieldDataMap,
    formSubmitting,
    formDoneSubmitting,
    setFormInvalid,
    setFormValid,
} from "../promptSlice";
import { SapienInertia } from "@/inertia-utils/hooks";
import {
    gotDecisionLogSelections,
    gotOthersSelections,
} from "../othersSelectionsSlice";

// used in SimulationDisplayWrapper
export const usePromptStore = () => {
    const dispatch = useAppDispatch();

    const setPromptAndFormBlockDataFromContentBlocks = useCallback(
        (contentBlocks: ContentBlock[]) => {
            let promptArray = [];

            let questionContentBlockArray = contentBlocks.filter(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType.Question
            );

            let questionContentBlocksGroupedByParentId = groupBy(
                questionContentBlockArray,
                "parent_content_block_id"
            );

            let formContentBlockArray = contentBlocks.filter(
                (contentBlock) =>
                    contentBlock.content_block_type === ContentBlockType.Form
            );

            let promptsByForm = formContentBlockArray.reduce(
                (map, formBlock) => {
                    let questionContentBlocksInForm =
                        questionContentBlocksGroupedByParentId[formBlock.id];

                    let promptsInForm = getPromptsFromContentBlocks(
                        questionContentBlocksInForm
                    );

                    promptArray = [...promptArray, ...promptsInForm];

                    return { ...map, [formBlock.id]: promptsInForm };
                },
                {}
            );

            dispatch(gotPrompts(mapById(promptArray)));
            dispatch(gotFormContentBlocks(mapById(formContentBlockArray)));
            dispatch(gotPromptsByForm(promptsByForm));
        },
        [dispatch]
    );


    const prompts = useAppSelector(
        (state) => state.promptStore.prompts
    );

    return {
        setPromptAndFormBlockDataFromContentBlocks,
        prompts,
    };
};

// used in SimulationDisplayWrapper and ContentBlockDisplay
export const useOthersSelections = () => {
    const dispatch = useAppDispatch();

    const _othersSelectionsByContentBlockId = useAppSelector(
        (state) =>
            state?.othersSelectionsStore?.othersSelectionsByContentBlockId
    );

    const decisionLogSelections = useAppSelector(
        (state) => state?.othersSelectionsStore.decisionLogSelections
    );

    const _gotOthersSelections = useCallback(
        (selections: Selection[]) => {
            dispatch(gotOthersSelections(selections));
        },
        [dispatch]
    );

    const _gotDecisionLogSelections = useCallback(
        (selections: (SelectionShape & { prompt: PromptShape })[]) => {
            dispatch(gotDecisionLogSelections(selections));
        },
        [dispatch]
    );

    return {
        othersSelectionsByContentBlockId: _othersSelectionsByContentBlockId,
        gotOthersSelections: _gotOthersSelections,
        decisionLogSelections,
        gotDecisionLogSelections: _gotDecisionLogSelections,
    };
};

// used in SimulationDisplayWrapper
export const useGotSelections = () => {
    const dispatch = useAppDispatch();

    const _promptsByForm = useAppSelector(
        (state) => state.promptStore.promptsByForm
    );

    const gotSelections = useCallback(
        (selections: Selection[]) => {
            let selectionsGroupedByPromptId = groupBy(selections, "prompt_id");

            let selectionsByForm = Object.keys(_promptsByForm).reduce(
                (map, formBlockId) => {
                    let selectionsInForm = _promptsByForm[formBlockId].reduce(
                        (selectionArray, prompt) => {
                            return selectionsGroupedByPromptId[prompt.id]
                                ? [
                                      ...selectionArray,
                                      ...selectionsGroupedByPromptId[prompt.id],
                                  ]
                                : selectionArray;
                        },
                        []
                    );
                    return { ...map, [formBlockId]: selectionsInForm };
                },
                {}
            );

            dispatch(gotSelectionsByForm(selectionsByForm));
        },
        [dispatch, _promptsByForm]
    );

    return {
        gotSelections,
    };
};

// used in SimulationDisplayWrapper
export const useGotIdsForSavingSelections = () => {
    const dispatch = useAppDispatch();

    const _gotIdsForSavingSelections = useCallback(
        (teamId: string, simulationId: string) => {
            dispatch(
                gotIdsForSavingSelections({
                    teamId,
                    simulationId,
                })
            );
        },
        [dispatch]
    );

    return { gotIdsForSavingSelections: _gotIdsForSavingSelections };
};

export const useIsSubmitting = () => {
    const dispatch = useAppDispatch();
    const _isSubmitting = useAppSelector(
        (state) => state.promptStore.isSubmitting
    );
    const _setSubmitting = useCallback(() => {
        dispatch(formSubmitting());
    }, [dispatch]);
    const _setDoneSubmitting = useCallback(() => {
        dispatch(formDoneSubmitting());
    }, [dispatch]);

    const _isValid = useAppSelector((state) => !state.promptStore.isInvalid)
    const _setValid = useCallback(() => {
        dispatch(setFormValid());
    },[dispatch])
    const _setInValid = useCallback(() => {
        dispatch(setFormInvalid());
    },[dispatch])

    return {
        isSubmitting: _isSubmitting,
        setIsSubmitting: _setSubmitting,
        setDoneSubmitting: _setDoneSubmitting,
        isValid: _isValid,
        setValid: _setValid,
        setInvalid: _setInValid,
    };
};
// used in NestedContentBlockDisplay
export const useParticipantBlockFormProps = (formContentBlockId: string) => {
    const { setIsSubmitting } = useIsSubmitting();

    const questionFormObject = useAppSelector(
        (state) => state.promptStore.formData[formContentBlockId]
    );
    const teamId = useAppSelector((state) => state.promptStore.teamId);
    const simulationId = useAppSelector(
        (state) => state.promptStore.simulationId
    );
    const prompts = useAppSelector(
        (state) => state.promptStore.promptsByForm[formContentBlockId]
    );
    const selections = useAppSelector(
        (state) => state.promptStore.selectionsByForm[formContentBlockId]
    );
    const formValidationSchema = useAppSelector(
        (state) => state.promptStore.formValidationSchemaMap[formContentBlockId]
    );

    const getSaveSelectionsFunction = (
        teamId: string,
        simulationId: string
    ) => {
        const submit = async (selections: Selection[]) => {
            setIsSubmitting();
            const postBody: any = {
                selections,
                team_id: teamId,
                simulation_id: simulationId,
            };
            SapienInertia.post("selection.store", postBody, {
                onError(page) {
                    console.log("onError", page, typeof page);
                },
                onSuccess(page) {
                    console.log("onSuccess", page);
                },
                preserveScroll: true,
                only:['contentBlocks','othersSelections', 'selections','decisionLogSelections']
            });
        };

        return submit;
    };

    const getOnFormSubmitFunction = (
        prompts: Prompt[],
        selections: Selection[],
        saveSelections: (selections: Selection[]) => Promise<void>
    ) => {
        // FormContainer onSubmit
        // gets passed raw formObject from react-hook-form,
        // controlled question object added in,
        // selections recreated with helper function
        const onFormSubmit = async (
            formObject: FormContentBlockDefaultValuesObject
        ) => {

            let selectionsToSave = getSelectionData(
                prompts,
                selections,
                formObject,
                ""
            );

            if (saveSelections) {
                await saveSelections(selectionsToSave);
            } else {
                console.log(selectionsToSave);
            }
        };

        return onFormSubmit;
    };

    const saveSelections = useCallback(
        getSaveSelectionsFunction(teamId, simulationId),
        [teamId, simulationId]
    );

    const onFormSubmit = useCallback(
        getOnFormSubmitFunction(prompts, selections, saveSelections),
        [prompts, selections, saveSelections]
    );

    const [localQuestionFormObject, setLocalQuestionFormObject] =
        useState<FormContentBlockDefaultValuesObject>();

    useEffect(() => {
        if (
            !!questionFormObject &&
            !isEqual(questionFormObject, localQuestionFormObject)
        ) {
            setLocalQuestionFormObject(questionFormObject);
        }
    }, [questionFormObject]);

    return {
        questionFormObject: localQuestionFormObject,
        onFormSubmit,
        validationSchema: formValidationSchema,
    };
};

// used in SimulationDisplayWrapper
export const useParticipantFormAndQuestionData = () => {
    const dispatch = useAppDispatch();

    const _formContentBlocks = useAppSelector(
        (state) => state.promptStore.formContentBlocks
    );
    const _promptsByForm = useAppSelector(
        (state) => state.promptStore.promptsByForm
    );
    const _selectionsByForm = useAppSelector(
        (state) => state.promptStore.selectionsByForm
    );

    useEffect(() => {
        if (
            !!_formContentBlocks &&
            !!_promptsByForm &&
            !!_selectionsByForm &&
            Object.keys(_formContentBlocks).length &&
            Object.keys(_promptsByForm).length &&
            Object.keys(_selectionsByForm).length
        ) {
            let newFormData: {
                [index: string]: FormContentBlockDefaultValuesObject;
            } = {};

            let newFormValidationSchemaMap: {
                [formContentBlockId: string]: FormValidationSchema;
            } = {};

            Object.keys(_formContentBlocks).forEach((formBlockId) => {
                let newQuestionFormObject =
                    {} as FormContentBlockDefaultValuesObject;

                if (
                    _promptsByForm[formBlockId] !== undefined &&
                    _promptsByForm[formBlockId].length > 0 &&
                    _selectionsByForm[formBlockId] !== undefined
                ) {
                    let groupedSelections = groupBy(
                        _selectionsByForm[formBlockId] || [],
                        "prompt_id"
                    );

                    _promptsByForm[formBlockId].forEach((prompt) => {
                        let data = getQuestionData(
                            prompt,
                            prompt.options || [],
                            groupedSelections[prompt.id] || []
                        );

                        newQuestionFormObject = {
                            ...newQuestionFormObject,
                            [prompt.id]: data,
                        } as FormContentBlockDefaultValuesObject;
                    });
                }

                newFormData = {
                    ...newFormData,
                    [formBlockId]: newQuestionFormObject,
                };

                newFormValidationSchemaMap = {
                    ...newFormValidationSchemaMap,
                    [formBlockId]: getFormValidationSchema(
                        _promptsByForm[formBlockId] || []
                    ),
                };
            });

            dispatch(gotFormData(newFormData));
            dispatch(gotFormValidationSchemaMap(newFormValidationSchemaMap));
        }
    }, [_formContentBlocks, _promptsByForm, _selectionsByForm, dispatch]);

    return {};
};

// keep some logic for controlled data in case we want to set it when watched form values change
// export const useParticipantControlledFormFieldDataMap = () => {
//     const dispatch = useAppDispatch();

//     const _controlledFormFieldDataMap = useAppSelector(
//         (state) => state.promptStore.controlledFormFieldDataMap
//     );

//     const _gotControlledFormFieldDataMap = useCallback(
//         (map: {
//             [formContentBlockId: string]: {
//                 [promptId: string]: SelectionShape[];
//             };
//         }) => {
//             dispatch(gotControlledFormFieldDataMap(map));
//         },
//         [dispatch]
//     );

//     // const setControlledFormFieldDataMap = useCallback(
//     //     (
//     //         formBlockId: string,
//     //         promptId: string,
//     //         selections: SelectionShape[]
//     //     ) => {
//     //         let newControlledFormFieldDataMap = {
//     //             ..._controlledFormFieldDataMap,
//     //             [formBlockId]: {
//     //                 ..._controlledFormFieldDataMap[formBlockId],
//     //                 [promptId]: selections,
//     //             },
//     //         };

//     //         _gotControlledFormFieldDataMap(newControlledFormFieldDataMap);
//     //     },
//     //     [_controlledFormFieldDataMap, _gotControlledFormFieldDataMap]
//     // );

//     return {
//         controlledFormFieldDataMap: _controlledFormFieldDataMap,
//         gotControlledFormFieldDataMap: _gotControlledFormFieldDataMap,
//         // setControlledFormFieldDataMap,
//     };
// };
