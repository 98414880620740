import React, { useCallback, useEffect, useState } from "react";
import {
    Popover,
    PopoverButton,
    PopoverPanel,
    useClose,
} from "@headlessui/react";
import { ColorPicker } from "./ColorPicker";
import ColorChooser from "./ColorChooser";
import { useColorThemes } from "@/Pages/ELearning/color-themes/useColorThemes";
import { useSelectedSimulation } from "@/hooks";
import { BrushIcon } from "lucide-react";
import { isDark } from "@/tailwind-colors/util";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { createPortal } from "react-dom";
import { atom, useAtom } from "jotai";

const openColorPortalAtom = atom<string | null>("");
openColorPortalAtom.debugLabel = "openColorPortalAtom";

type Props = {
    value: string;
    onChange: (value: string) => void;
    onGradientChange: (value: App.Data.DesignColorGradientData) => void;
    gradientColors?: string[];
    contentBlockId?: string;
    disallowGradients?: boolean;
};

export function ColorPickerPopover(props: Props) {
    const element = useGetElementAsync(
        `[data-content-block-id="${props.contentBlockId}"]`,
    );

    const { value, gradientColors } = props;

    const { selectedSimulation } = useSelectedSimulation();

    const initialGradient: App.Data.DesignColorGradientData = {
        id: null,
        name: "",
        angle: "r",
        simulationId: selectedSimulation?.id ?? "",
        userId: "",
        designColors:
            gradientColors?.length > 0
                ? gradientColors.map((color) => ({
                      id: "",
                      css_color: color,
                      name: "",
                      user_id: "",
                      simulation_id: selectedSimulation?.id ?? "",
                  }))
                : [
                      {
                          id: "",
                          css_color: value,
                          name: "",
                          user_id: "",
                          simulation_id: selectedSimulation?.id ?? "",
                      },
                      {
                          id: "",
                          css_color: value,
                          name: "",
                          user_id: "",
                          simulation_id: selectedSimulation?.id ?? "",
                      },
                  ],
    };

    const [gradient, setGradient] =
        useState<App.Data.DesignColorGradientData>(initialGradient);

    const [mode, setModeState] = useState<
        "color" | "gradient" | "color-chooser"
    >("color-chooser");

    const [isOpen, setIsOpen] = useState(false);

    const hasViaColor = !!gradient.designColors[2]?.css_color;

    if (element?.getBoundingClientRect()?.width < 400) {
        return (
            <ColorPickerPopoverPortal element={element} {...props}>
                <ColorPickerPopoverContents {...props} />
            </ColorPickerPopoverPortal>
        );
    }

    return (
        <div className="flex flex-col justify-center gap-2">
            <Popover>
                <PopoverButton
                    className="flex items-center"
                    onClick={() => setIsOpen(true)}
                >
                    <div
                        className={`flex ${value} h-8 w-8 items-center justify-center rounded-full border
                        border-gray-300 from-[var(--from-color)] to-[var(--to-color)] ${
                            hasViaColor ? "via-[var(--via-color)]" : ""
                        }`}
                        style={
                            {
                                backgroundColor: value,
                                "--from-color": gradientColors?.[0] || "",
                                "--to-color":
                                    gradientColors?.[
                                        gradientColors.length - 1
                                    ] || "",
                                "--via-color":
                                    gradientColors?.length > 2
                                        ? gradientColors?.[1] || ""
                                        : "",
                            } as React.CSSProperties
                        }
                        data-is-gradient={mode === "gradient"}
                    >
                        <BrushIcon
                            aria-label="Choose color"
                            className=" h-4 w-4"
                            style={{
                                color: isDark(gradientColors?.[1] || value)
                                    ? "white"
                                    : "black",
                            }}
                        />
                    </div>
                </PopoverButton>
                <PopoverPanel
                    static={isOpen}
                    className="data-[open=true]:animate-fade-in data-[open=false]:animate-fade-out absolute
                        z-[999999] mt-4 flex min-w-60 flex-col gap-2 rounded-lg border border-gray-300
                        bg-white pb-2 pt-0 shadow-md scrollbar scrollbar-thumb-gray-500
                        scrollbar-thumb-rounded-full scrollbar-w-2 data-[is-open=false]:hidden"
                    data-is-open={isOpen}
                    style={{
                        transitionBehavior: "allow-discrete",
                    }}
                >
                    <ColorPickerPopoverContents {...props} />
                </PopoverPanel>
            </Popover>
            {/* {ReactDOM.createPortal(
                <button
                    className="overlay fixed inset-0 data-[is-open=false]:pointer-events-none
                        data-[is-open=true]:block data-[is-open=false]:hidden"
                    onClick={() => {
                        setIsOpen(false);
                        close();
                    }}
                    data-is-open={isOpen}
                />,
                document.body,
            )} */}
        </div>
    );
}

function ColorPickerPopoverPortal({
    children,
    element,
    gradientColors,
    value,
}: {
    children: React.ReactNode;
    element: HTMLElement;
} & Props) {
    const hasViaColor = !!gradientColors[2];
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            if (!!(e.target as HTMLElement).closest(".child-popover-fixed"))
                return;
            if (!element.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [element]);

    // const isOpen =
    //     openColorPortalId === element.getAttribute("data-content-block-id");

    return (
        <>
            <button
                className={`flex ${value} h-8 w-8 items-center justify-center rounded-full border
                border-gray-300 from-[var(--from-color)] to-[var(--to-color)] ${
                    hasViaColor ? "via-[var(--via-color)]" : ""
                }`}
                style={
                    {
                        backgroundColor: value,
                        "--from-color": gradientColors?.[0] || "",
                        "--to-color":
                            gradientColors?.[gradientColors.length - 1] || "",
                        "--via-color":
                            gradientColors?.length > 2
                                ? gradientColors?.[1] || ""
                                : "",
                    } as React.CSSProperties
                }
                data-is-gradient={gradientColors.length > 1}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpen(true);
                    // setOpenColorPortalId(
                    //     element.getAttribute("data-content-block-id"),
                    // );
                }}
            >
                <BrushIcon
                    aria-label="Choose color"
                    className=" h-4 w-4"
                    style={{
                        color: isDark(gradientColors?.[1] || value)
                            ? "white"
                            : "black",
                    }}
                />
            </button>
            {createPortal(
                <>
                    <div
                        className={`child-popover-fixed fixed left-2 top-[6.5rem] z-[99999999] w-[432px] rounded-lg
                        bg-white p-4 opacity-0 shadow-lg transition-all duration-300
                        data-[is-open=false]:pointer-events-none data-[is-open=true]:pointer-events-auto
                        data-[is-open=true]:opacity-100 ${isOpen && "i-am-open"}`}
                        data-is-open={isOpen}
                    >
                        {children}
                    </div>
                </>,
                document.body,
            )}
        </>
    );
}

function ColorPickerPopoverContents({
    value,
    onChange,
    onGradientChange,
    gradientColors,
    disallowGradients
}: Props) {
    const { selectedSimulation } = useSelectedSimulation();

    const initialGradient: App.Data.DesignColorGradientData = {
        id: null,
        name: "",
        angle: "r",
        simulationId: selectedSimulation?.id ?? "",
        userId: "",
        designColors:
            gradientColors?.length > 0
                ? gradientColors.map((color) => ({
                      id: "",
                      css_color: color,
                      name: "",
                      user_id: "",
                      simulation_id: selectedSimulation?.id ?? "",
                  }))
                : [
                      {
                          id: "",
                          css_color: value,
                          name: "",
                          user_id: "",
                          simulation_id: selectedSimulation?.id ?? "",
                      },
                      {
                          id: "",
                          css_color: value,
                          name: "",
                          user_id: "",
                          simulation_id: selectedSimulation?.id ?? "",
                      },
                  ],
    };

    const [gradient, setGradient] =
        useState<App.Data.DesignColorGradientData>(initialGradient);

    const handleGradientChange = useCallback(
        (gradient: App.Data.DesignColorGradientData) => {
            setGradient(gradient);
            onGradientChange(gradient);
        },
        [onGradientChange],
    );

    const handleChange = useCallback(
        (color: string, colorIndex?: 0 | 1) => {
            if (colorIndex !== undefined) {
                const newGradient = { ...gradient };
                newGradient.designColors[colorIndex].css_color = color;
                console.log("newGradient", newGradient);
                setGradient(() => newGradient);
            } else {
                onChange(color);
            }
        },
        [gradient, onGradientChange, onChange],
    );

    const [mode, setModeState] = useState<
        "color" | "gradient" | "color-chooser"
    >("color-chooser");

    const [isOpen, setIsOpen] = useState(false);

    const setMode = useCallback(
        (mode: "color" | "gradient" | "color-chooser") => {
            setIsOpen(() => true);
            setModeState(mode);
        },
        [isOpen, mode, setModeState],
    );

    const { saveColor, saveGradient } = useColorThemes();

    return (
        <>
            <nav className="relative flex gap-2 p-2 pt-4">
                <button
                    className="grow rounded-[0.225rem] p-2 text-center text-xs transition-all duration-200
                        data-[active=true]:bg-gray-100 data-[active=true]:font-semibold
                        hover:bg-gray-100"
                    data-active={mode === "color-chooser"}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setMode("color-chooser");
                    }}
                >
                    Palette
                </button>
                <button
                    className="grow rounded-[0.225rem] p-2 text-center text-sm transition-all duration-200
                        data-[active=true]:bg-gray-100 data-[active=true]:font-semibold
                        hover:bg-gray-100"
                    data-active={mode === "color"}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setMode("color");
                    }}
                >
                    Solid
                </button>

                {!disallowGradients && (
                    <button
                        className="grow rounded-[0.225rem] p-2 text-center text-sm transition-all duration-200
                            data-[active=true]:bg-gray-100 data-[active=true]:font-semibold
                            hover:bg-gray-100"
                        data-active={mode === "gradient"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setMode("gradient");
                        }}
                    >
                        Gradient
                    </button>
                )}
            </nav>

            {mode === "color-chooser" ? (
                <div
                    className="max-h-96 overflow-y-auto px-2 scrollbar scrollbar-thumb-gray-500
                        scrollbar-thumb-rounded-full scrollbar-w-2"
                >
                    <ColorChooser
                        selectedColor={value}
                        selectColor={(color) => {
                            handleChange(color);
                        }}
                        selectGradient={handleGradientChange}
                    />
                </div>
            ) : (
                <div
                    className="max-h-[500px] overflow-auto scrollbar scrollbar-thumb-gray-500
                        scrollbar-thumb-rounded-full scrollbar-w-2 data-[is-gradient=true]:px-4"
                    data-is-gradient={mode === "gradient"}
                >
                    <ColorPicker
                        value={value}
                        onChange={handleChange}
                        onGradientChange={handleGradientChange}
                        isInGradientMode={mode === "gradient"}
                        initialGradient={gradient}
                    />
                </div>
            )}

            {mode !== "color-chooser" && (
                <div className="flex p-2">
                    <button
                        className="grow rounded-[0.225rem] bg-sapien-blue p-2 text-center text-xs font-semibold
                            text-white transition-all duration-200"
                        onClick={() => {
                            if (mode === "color") {
                                console.log("value", value);
                                saveColor({
                                    simulationId: selectedSimulation.id,
                                    color: value,
                                });
                            } else {
                                saveGradient({
                                    simulationId: selectedSimulation.id,
                                    colors: gradient.designColors.map(
                                        (color) => color.css_color,
                                    ),
                                    angle: gradient.angle,
                                });
                            }
                        }}
                    >
                        Add to Theme
                    </button>
                </div>
            )}
        </>
    );
}
