import { useCallback, useEffect, useMemo, useState } from "react";
import { ContentBlock, ContentBlockShape, ContentBlockType } from "@/models";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import {
    setActiveLineage,
    setLeftMenuSection,
    setRightMenuSection,
    setChartContentBlock,
} from "@/redux-state/contentEditorDataSlice";
import {
    gotNestedContentBlocks,
    setRoundId,
} from "@/redux-state/contentBlockDataSlice";
import { getSortedNestedObjects, getLineageIds } from "@/util";
import { isEqual } from "lodash";
import { AddableCategory, AddableSubcategory } from "@/model-configs";
import {
    LeftMenuSectionType,
    RightMenuSectionType,
} from "@/Pages/Admin/editor";
import { useFindContentBlockMethods } from "./useContentBlockStore";
import { useGetActiveContentBlockId } from "./activeContentBlock";
import { atom, useAtom } from "jotai";

export const useSelectedRoundId = () => {
    const dispatch = useAppDispatch();

    const _roundId = useAppSelector((state) => state.contentBlockStore.roundId);

    const _setRoundId = useCallback(
        (roundId: string) => {
            dispatch(setRoundId(roundId));
        },
        [dispatch],
    );

    return {
        selectedRoundId: _roundId,
        setRoundId: _setRoundId,
    };
};

// export const useEditorIsSelected = (contentBlockId: string) => {
//     const [isSelected, setIsSelected] = useState<boolean>(false);

//     const _activeId = useAppSelector(
//         (state) => state.contentEditorStore.activeId,
//     );

//     useEffect(() => {
//         let newIsSelected = contentBlockId && contentBlockId === _activeId;
//         if (newIsSelected !== isSelected) {
//             setIsSelected(newIsSelected);
//         }
//     }, [contentBlockId, _activeId]);

//     return {
//         isSelected,
//         activeId: _activeId,
//     };
// };

export const useFlatContentBlock = (contentBlockId: string) => {
    const flatContentBlock = useAppSelector(
        (state) => state.contentBlockStore.contentBlocks[contentBlockId],
    );

    return {
        flatContentBlock,
    };
};

export const useRoundFrameProperties = () => {
    const hasNavbar = useAppSelector(
        (state) =>
            !!state.contentBlockStore.contentBlocks &&
            !!Object.values(state.contentBlockStore.contentBlocks).some(
                (contentBlock) =>
                    contentBlock.content_block_type === ContentBlockType.Navbar,
            ),
    );
    const hasInbox = useAppSelector(
        (state) =>
            !!state.contentBlockStore.contentBlocks &&
            !!Object.values(state.contentBlockStore.contentBlocks).some(
                (contentBlock) =>
                    contentBlock.content_block_type === ContentBlockType.Inbox,
            ),
    );
    const hasBottomDrawer = useAppSelector((state) =>
        Object.values(state.contentBlockStore.contentBlocks).some(
            (contentBlock) =>
                contentBlock.content_block_type ===
                ContentBlockType["Bottom Drawer"],
        ),
    );

    const hasTopDrawer = useAppSelector((state) => {
        return Object.values(state.contentBlockStore.contentBlocks).some(
            (contentBlock) =>
                contentBlock.content_block_type ===
                ContentBlockType["Top Drawer"],
        );
    });

    return {
        hasNavbar,
        hasInbox,
        hasBottomDrawer,
        hasTopDrawer,
    };
};

export const useSetDerivedData = () => {
    const dispatch = useAppDispatch();

    const _contentBlocks = useAppSelector(
        (state) => state.contentBlockStore.contentBlocks,
    );

    const setDerivedData = useCallback(() => {
        if (_contentBlocks !== undefined) {
            const { unflattenedArray } = getSortedNestedObjects<
                ContentBlock | ContentBlockShape
            >(
                "parent_content_block_id",
                "contentBlocks",
                Object.values(_contentBlocks),
            );
            dispatch(gotNestedContentBlocks(unflattenedArray));
        }
    }, [_contentBlocks, dispatch]);

    useEffect(() => {
        setDerivedData();
    }, [setDerivedData]);

    return {};
};

export const useEditorActiveLineage = () => {
    const _activeLineage = useAppSelector(
        (state) => state.contentEditorStore.activeLineage,
    );
    return { activeLineage: _activeLineage };
};

export const useSetActiveLineage = () => {
    const activeContentBlockId = useGetActiveContentBlockId();
    const dispatch = useAppDispatch();

    const _activeLineage = useAppSelector(
        (state) => state.contentEditorStore.activeLineage,
    );

    const _lineageIds = useAppSelector((state) =>
        getLineageIds(
            activeContentBlockId,
            state.contentBlockStore.contentBlocks,
            "parent_content_block_id",
        ),
    );

    const handleSetActiveLineage = useCallback(() => {
        if (_lineageIds !== undefined && _lineageIds.length > 0) {
            let newActiveLineage = _lineageIds.reduce((map, id, index) => {
                return { ...map, ...{ [id]: index + 1 } };
            }, {});
            if (!isEqual(newActiveLineage, _activeLineage)) {
                dispatch(setActiveLineage(newActiveLineage));
            }
        } else {
            if (!isEqual({}, _activeLineage)) {
                dispatch(setActiveLineage({}));
            }
        }
    }, [_lineageIds, _activeLineage, dispatch]);

    useEffect(() => {
        handleSetActiveLineage();
    }, [handleSetActiveLineage]);

    return {};
};

// const keysForComparison = [
//     "id",
//     "content",
//     "theme",
//     "title",
//     "rich_text",
//     "behaviors",
//     "table_data",
// ];
// const filterContentBlockPropsForComparison = (block: ContentBlock) => {
//     const filteredBlock = {};
//     keysForComparison.forEach((key) => {
//         if (!!block[key]) {
//             filteredBlock[key] = block[key];
//         }
//     });
//     return filteredBlock;
// };
// const getHasUnsavedChanges = (active: ContentBlock, original: ContentBlock) => {
//     const filteredActive = filterContentBlockPropsForComparison(active);
//     const filteredOriginal = filterContentBlockPropsForComparison(original);
//     return !isEqual(filteredActive, filteredOriginal);
// };

// export const useSetHasUnsavedChanges = () => {
//     const dispatch = useAppDispatch();

//     const _activeContentBlock = useAppSelector(
//         (state) => state.contentEditorStore.activeContentBlock,
//     );
//     const _originalContentBlock = useAppSelector(
//         (state) => state.contentEditorStore.originalContentBlock,
//     );
//     const _hasUnsavedChanges = useAppSelector(
//         (state) => state.contentEditorStore.hasUnsavedChanges,
//     );

//     const handleSetHasUnsavedChanges = useCallback(() => {
//         if (
//             _activeContentBlock !== undefined &&
//             _originalContentBlock !== undefined
//         ) {
//             let newHasUnsavedChanges = getHasUnsavedChanges(
//                 _activeContentBlock,
//                 _originalContentBlock,
//             );

//             if (newHasUnsavedChanges !== _hasUnsavedChanges) {
//                 dispatch(setHasUnsavedChanges(newHasUnsavedChanges));
//             }
//         }
//     }, [
//         _activeContentBlock,
//         _originalContentBlock,
//         _hasUnsavedChanges,
//         dispatch,
//     ]);

//     useEffect(() => {
//         handleSetHasUnsavedChanges();
//     }, [handleSetHasUnsavedChanges]);

//     return {};
// };

// export const useSetActiveContentBlock = () => {
//     const dispatch = useAppDispatch();

//     const _setActiveContentBlock = useCallback(
//         (contentBlock: ContentBlock) => {
//             dispatch(setActiveContentBlock(contentBlock));
//         },
//         [dispatch],
//     );

//     return {
//         setActiveContentBlock: _setActiveContentBlock,
//     };
// };

// export const useEditActiveContentBlock = () => {
//     const dispatch = useAppDispatch();

//     const _activeContentBlock = useAppSelector(
//         (state) => state.contentEditorStore.activeContentBlock,
//     );

//     const _editActiveContentBlock = useCallback(
//         (property: keyof ContentBlockShape, value: any) => {
//             const updatedBlock = {
//                 ..._activeContentBlock,
//                 ...{ [property]: value },
//             } as ContentBlock;

//             if (
//                 _activeContentBlock.content_block_type ===
//                     ContentBlockType.Link &&
//                 property === "content"
//             ) {
//                 updatedBlock.title = value;
//             }

//             dispatch(setActiveContentBlock(updatedBlock));
//         },
//         [dispatch, _activeContentBlock],
//     );

//     return {
//         editActiveContentBlock: _editActiveContentBlock,
//     };
// };

export const useChartContentBlock = () => {
    const dispatch = useAppDispatch();

    const { getContentBlock, getContentBlockWithChildren } =
        useFindContentBlockMethods();

    const _chartContentBlock = useAppSelector(
        (state) => state?.contentEditorStore?.chartContentBlock,
    );

    const _chartContentBlockChildId = useAppSelector(
        (state) => state?.contentEditorStore?.chartContentBlockChildId,
    );

    const _modelDataContentBlockId = useAppSelector(
        (state) => state?.contentEditorStore?.modelDataContentBlockId,
    );

    const _setChartContentBlock = useCallback(
        (chartContentBlock: ContentBlock | ContentBlockShape | null) => {
            //if we're doing a table, we need to get the table body
            if (
                chartContentBlock?.content_block_type === ContentBlockType.Table
            ) {
                const tableBody = [...getContentBlockWithChildren(
                    chartContentBlock.id,
                ).contentBlocks].find(
                    (child) =>
                        child.content_block_type ===
                        ContentBlockType["Table Body"],
                );
                if (tableBody) {
                    dispatch(setChartContentBlock(tableBody));
                }
            } else
                dispatch(
                    setChartContentBlock(chartContentBlock as ContentBlock),
                );
        },
        [dispatch],
    );

    useEffect(() => {
        const block = getContentBlock(_modelDataContentBlockId);
        if (block) dispatch(setChartContentBlock(block));
    }, [_modelDataContentBlockId]);

    useEffect(() => {
        if (_chartContentBlockChildId) {
            const block = getContentBlock(_chartContentBlockChildId);
            if (block) {
                const parent = getContentBlock(block.parent_content_block_id);

                if (parent) {
                    dispatch(setChartContentBlock(parent));
                }
            }
        }
    }, [_chartContentBlockChildId]);

    return {
        chartContentBlock: _chartContentBlock,
        setChartContentBlock: _setChartContentBlock,
    };
};

const editorSizes = {
    toolbar: "36px",
    leftMenu: "356px",
    rightMenu: "0px",
};
const editorSizesAtom = atom<typeof editorSizes>(editorSizes);
export const useEditorMenuSections = () => {
    const dispatch = useAppDispatch();

    const _leftMenuSection = useAppSelector(
        (state) => state.contentEditorStore.leftMenuSection,
    );
    const _rightMenuSection = useAppSelector(
        (state) => state.contentEditorStore.rightMenuSection,
    );
    const _setLeftMenuSection = useCallback(
        (section: LeftMenuSectionType) => {
            dispatch(setLeftMenuSection(section));
        },
        [dispatch],
    );
    const _setRightMenuSection = useCallback(
        (section: RightMenuSectionType) =>
            dispatch(setRightMenuSection(section)),
        [dispatch],
    );

    const [statefulEditorSizes, setStatefulEditorSizes] =
        useAtom<typeof editorSizes>(editorSizesAtom);

    const isLeftMenuOpen = useMemo(() => {
        return statefulEditorSizes.leftMenu === editorSizes.leftMenu;
    }, [statefulEditorSizes]);

    const isRightMenuOpen = useMemo(
        () => statefulEditorSizes.rightMenu !== "0px",
        [statefulEditorSizes],
    );

    const toggleRightMenu = useCallback(() => {
        let rightMenu = editorSizes.rightMenu;
        if (statefulEditorSizes.rightMenu === editorSizes.rightMenu) {
            rightMenu = "360px";
        }
        setStatefulEditorSizes((previousEditorSizes) => ({
            ...previousEditorSizes,
            rightMenu,
        }));
    }, [statefulEditorSizes]);

    const toggleLeftMenu = useCallback(() => {
        setStatefulEditorSizes((previousEditorSizes) => ({
            ...previousEditorSizes,
            leftMenu: isLeftMenuOpen ? "0px" : editorSizes.leftMenu,
        }));
    }, [isLeftMenuOpen]);

    return {
        leftMenuSection: _leftMenuSection,
        rightMenuSection: _rightMenuSection,
        setLeftMenuSection: _setLeftMenuSection,
        setRightMenuSection: _setRightMenuSection,
        statefulEditorSizes,
        isLeftMenuOpen,
        toggleLeftMenu,
        toggleRightMenu,
        isRightMenuOpen,
    };
};

export const useAddContentBlockMenuState = () => {
    const [selectedCategory, setSelectedCategory] =
        useState<AddableCategory>("Components");

    const [openSubcategories, setOpenSubcategories] = useState<{
        [index in AddableSubcategory]: boolean;
    }>({
        Basic: true,
        Typography: true,
        Container: true,
        Collection: true,
        Media: true,
        Data: true,
        Question: true,
        Hero: true,
        Cards: true,
    });

    return {
        selectedCategory,
        setSelectedCategory,
        openSubcategories,
        setOpenSubcategories,
    };
};
