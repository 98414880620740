import { defaultTableRichText } from "@/Pages/ELearning/LessonDesign/content-block-management/defaultRichtext";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { v4 as uuidv4 } from 'uuid';

export function addColumn(table: ContentBlockShape, index: number) {
    let thead = table?.contentBlocks.find(
        (block) => block.content_block_type === ContentBlockType["Table Head"],
    );
    let tbody = table?.contentBlocks.find(
        (block) => block.content_block_type === ContentBlockType["Table Body"],
    );

    if (thead) {
        thead = {
            ...thead,
            contentBlocks: thead.contentBlocks.map((tr, i) => {
                const id = uuidv4()
                return {
                    ...tr,
                    contentBlocks: [
                        ...tr.contentBlocks.slice(0, index),
                        {
                            ...tr.contentBlocks[index],
                            id,
                            weight: index,
                            contentBlocks: [
                                {
                                    round_id: tr.round_id,
                                    content_block_type:
                                        ContentBlockType["Rich Text"],
                                    rich_text: defaultTableRichText("", index),
                                    weight: 0,
                                    theme: {
                                        tailwindClasses: [
                                            "flex",
                                            "flex-col",
                                            "justify-start",
                                            "items-center",
                                            "gap-4",
                                            "p-4",
                                            "w-full",
                                        ],
                                    },
                                    parent_content_block_id: id,

                                },
                            ],
                        },
                        ...tr.contentBlocks.slice(index).map((td) => {
                            return {
                                ...td,
                                weight: td.weight + 1,
                            };
                        }),
                    ],
                };
            }),
        };
    }

    if (tbody) {
        tbody = {
            ...tbody,
            contentBlocks: tbody.contentBlocks.map((tr, i) => {
                console.log("tr", tr, i, tr.contentBlocks[index]);

                return {
                    ...tr,
                    contentBlocks: [
                        ...tr.contentBlocks.slice(0, index),
                        {
                            ...tr.contentBlocks[index],
                            id: null,
                            weight: index,
                            contentBlocks: [
                                {
                                    round_id: tr.round_id,
                                    content_block_type:
                                        ContentBlockType["Rich Text"],
                                    rich_text: defaultTableRichText("", index),
                                    weight: 0,
                                    theme: {
                                        tailwindClasses: [
                                            "flex",
                                            "flex-col",
                                            "justify-start",
                                            "items-center",
                                            "gap-4",
                                            "p-4",
                                            "w-full",
                                        ],
                                    },
                                },
                            ],
                        },
                        ...tr.contentBlocks.slice(index).map((td) => {
                            return {
                                ...td,
                                weight: td.weight + 1,
                            };
                        }),
                    ],
                };
            }),
        };
    }

    return { ...table, contentBlocks: [thead, tbody].filter(Boolean) };
}
