import {
    ContentBlockShape,
    ContentBlockType,
    TimeHorizon,
    VariableValue,
} from "@/models";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { abbrNum } from "@/modules/shared/charts/chart-util";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import { gotVariableValues } from "@/redux-state/variableValueSlice";
import { useFormatVariableValue } from "./useSimulationStore";
import { useCallback } from "react";
import { ChartType } from "@/Pages/Admin/editor/charts-and-tables/chart-wizard/ChartWizardReducer";

export function useVariableValueStore() {
    const dispatch = useAppDispatch();

    const formatVariableValue = useFormatVariableValue();

    const _variableValues = useAppSelector(
        (state) => state.variableValueStore.variableValues,
    );

    const _variableValuesByContentBlockId = useAppSelector(
        (state) => state?.variableValueStore?.variableValuesByContentBlockId,
    );

    const _modelVariables = useAppSelector(
        (state) => state?.modelBuilderStore?.modelVariableStore?.modelVariables,
    );

    const _timeHorizons = useAppSelector(
        (state) => state?.timeHorizonStore.timeHorizons,
    );

    const _gotVariableValues = useCallback(
        (variableValues: VariableValue[]) => {
            dispatch(gotVariableValues(variableValues));
        },
        [dispatch],
    );

    const getVariableValuesForContentBlock = useCallback(
        (
            contentBlock: ContentBlockShape,
            variableValues: { [index: string]: VariableValue },
        ) => {
            if (!variableValues || !contentBlock.contentBlockModelDataSources)
                return null;
            return Object.values(variableValues)
                .filter((variableValue) =>
                    contentBlock.contentBlockModelDataSources.some(
                        (modelDataSource) =>
                            modelDataSource.model_variable_id ===
                                variableValue.model_variable_id &&
                            (modelDataSource?.modelVariable?.uses_time ===
                                false ||
                                modelDataSource.timeHorizons.some(
                                    (th) =>
                                        th.id === variableValue.time_horizon_id,
                                )),
                    ),
                )
                .map((variableValue, i) => {
                    const mds = contentBlock.contentBlockModelDataSources.find(
                        (source) =>
                            source.model_variable_id ===
                            variableValue.model_variable_id,
                    );
                    return {
                        ...variableValue,
                        label: mds?.label || variableValue.modelVariable?.label,
                    };
                });
        },
        [],
    );

    const getFormattedChartData = useCallback(
        (
            contentBlock: ContentBlockShape,
            variableValues: { [index: string]: VariableValue },
        ): FormattedChartValue[] => {
            if (
                contentBlock.chart_data &&
                contentBlock.chart_data?.length ===
                    contentBlock.contentBlockModelDataSources?.[0]?.timeHorizons
                        ?.length
            ) {
                return contentBlock.chart_data as FormattedChartValue[];
            }
            const selectedVariableValues = getVariableValuesForContentBlock(
                contentBlock,
                variableValues,
            );

            if (selectedVariableValues?.length) {
                return selectedVariableValues
                    .map((variableValue, idx) => {
                        let variable = variableValue.modelVariable;
                        if (!variable)
                            variable =
                                _modelVariables[
                                    variableValue.model_variable_id
                                ];

                        let chartType: ChartType =
                            contentBlock.content_block_type
                                .split("_")[0]
                                ?.toLowerCase() as ChartType;
                        const value: FormattedChartValue = {
                            x: variableValue.time_index,
                            y: variableValue.numerical_value,
                            label: variableValue.label || variable.label || "",
                            theme: contentBlock?.theme,
                            variable_id: variableValue.model_variable_id,
                            chartType,
                            displayText: formatVariableValue(
                                variable.unit,
                                variableValue.numerical_value,
                                variable.is_integer,
                                (value) =>
                                    contentBlock.theme?.abbreviateYAxis !==
                                    false
                                        ? abbrNum(value, [1, 1, 1, 0])
                                        : value > 10000
                                          ? value.toLocaleString()
                                          : Math.round(value).toString(),
                            ),
                        };
                        return value;
                    })
                    .sort((a, b) => {
                        return a.x > b.x ? 1 : -1;
                    });
            }
        },
        [_modelVariables],
    );

    const getMappedFormattedChartData = useCallback(
        (contentBlocks: ContentBlockShape[]) => {
            return contentBlocks.reduce((carry, contentBlock) => {
                return {
                    ...carry,
                    [contentBlock.id]: getFormattedChartData(
                        contentBlock,
                        _variableValues,
                    ),
                };
            }, {});
        },
        [_variableValues],
    );

    const getDataForTableRow = (
        tableRow: ContentBlockShape,
        variableValues: VariableValue[],
        timeHorizons: { [index: string]: TimeHorizon },
    ) => {
        return variableValues
            .filter((variableValue) => {
                return tableRow.contentBlockModelDataSources?.some(
                    (source) =>
                        source.timeHorizons?.some(
                            (timeHorizon) =>
                                timeHorizon.id ===
                                variableValue.time_horizon_id,
                        ) || !source.timeHorizons?.length,
                );
            })
            .sort((a, b) => {
                return a.time_index > b.time_index ? 1 : -1;
            });
    };

    const getMappedDataForTableRows = useCallback(
        (
            contentBlocks: ContentBlockShape[],
        ): { [index: string]: VariableValue[] } => {
            if (!contentBlocks) return {};
            return contentBlocks
                .filter(
                    (contentBlock) =>
                        !!_variableValuesByContentBlockId[contentBlock.id],
                )
                .reduce((carry, contentBlock) => {
                    return {
                        ...carry,
                        [contentBlock.id]: getDataForTableRow(
                            contentBlock,
                            _variableValuesByContentBlockId[contentBlock.id],
                            _timeHorizons,
                        ),
                    };
                }, {});
        },
        [_variableValuesByContentBlockId, _modelVariables, _timeHorizons],
    );

    const generateContentBlocksForModelTable = useCallback(
        (table: ContentBlockShape) => {
            const tbody = table?.contentBlocks?.find(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Table Body"],
            );
            const thead = table?.contentBlocks?.find(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Table Head"],
            );

            let tableCopy = { ...table, contentBlocks: [] };

            if (tbody) {
                const bodyVariableValues = getMappedDataForTableRows(
                    tbody.contentBlocks,
                );

                if (
                    thead &&
                    Object.keys(bodyVariableValues)?.length > 1 &&
                    Array.isArray(Object.values(bodyVariableValues)[0]) &&
                    Object.values(bodyVariableValues)[0]?.[0]?.time_index !==
                        null &&
                    Object.values(bodyVariableValues)[0]?.[0]?.time_index !==
                        undefined
                ) {
                    const variableValues = Object.values(bodyVariableValues)[0];

                    const theadCopy: ContentBlockShape = {
                        ...thead,
                        weight: 0,
                        contentBlocks: [
                            {
                                content_block_type:
                                    ContentBlockType["Table Row"],
                                contentBlocks: [
                                    {
                                        weight: 0,
                                        parent_content_block_id: thead.id,
                                        content_block_type:
                                            ContentBlockType[
                                                "Table Header Cell"
                                            ],
                                        content: "Time Horizon",
                                    },
                                    ...variableValues
                                        .sort((a, b) => {
                                            return a.time_index > b.time_index
                                                ? 1
                                                : -1;
                                        })
                                        .map((variableValue, index) => {
                                            return {
                                                weight: index + 1,
                                                parent_content_block_id:
                                                    thead.id,
                                                content_block_type:
                                                    ContentBlockType[
                                                        "Table Header Cell"
                                                    ],
                                                content:
                                                    variableValue?.time_index !==
                                                    undefined
                                                        ? variableValue.time_index.toString()
                                                        : (
                                                              index + 1
                                                          ).toString(),
                                            };
                                        }),
                                ],
                            },
                        ],
                    };

                    tableCopy.contentBlocks = [theadCopy];
                }

                //TODO: Decide whether we handle existing cell content blocks here or at a higher level
                const tbodyCopy: ContentBlockShape = {
                    ...tbody,
                    weight: 1,
                    contentBlocks:
                        Object.keys(bodyVariableValues).length === 0
                            ? []
                            : tbody.contentBlocks
                                  .filter((row) => !!bodyVariableValues[row.id])
                                  .map((row, idx) => {
                                      const variableValuesForRow =
                                          bodyVariableValues[row.id];

                                      const block: ContentBlockShape = {
                                          ...row,
                                          contentBlocks:
                                              !variableValuesForRow.length
                                                  ? []
                                                  : [
                                                        {
                                                            content:
                                                                variableValuesForRow[0]
                                                                    .modelVariable
                                                                    .label,
                                                            parent_content_block_id:
                                                                row.id,
                                                            content_block_type:
                                                                ContentBlockType[
                                                                    "Table Header Cell"
                                                                ],
                                                            weight: 0,
                                                        },
                                                        ...variableValuesForRow.map(
                                                            (
                                                                variableValue,
                                                                timeHorizonIndex,
                                                            ) => {
                                                                return {
                                                                    content:
                                                                        formatVariableValue(
                                                                            variableValue
                                                                                .modelVariable
                                                                                .unit,
                                                                            variableValue.numerical_value,
                                                                            variableValue
                                                                                .modelVariable
                                                                                .is_integer,
                                                                        ),
                                                                    weight:
                                                                        timeHorizonIndex +
                                                                        1,
                                                                    parent_content_block_id:
                                                                        row.id,
                                                                    content_block_type:
                                                                        ContentBlockType[
                                                                            "Table Data Cell"
                                                                        ],
                                                                };
                                                            },
                                                        ),
                                                    ],
                                      };

                                      return block;
                                  }),
                };

                tableCopy.contentBlocks = [
                    ...tableCopy.contentBlocks,
                    tbodyCopy,
                ];
            }

            return tableCopy;
        },
        [_variableValues, _modelVariables, _timeHorizons],
    );

    const generateContentBlocksForLeaderBoard = useCallback(
        (leaderBoard: ContentBlockShape) => {
            const values: VariableValue[] =
                _variableValuesByContentBlockId[leaderBoard.id];

            if (values && Array.isArray(values)) {
                //leaderboards can only have a relationship to one ModelVariable
                const scope = values[0].scope;

                const scopedValues: { [index: string]: VariableValue[] } =
                    values.reduce((carry, variableValue) => {
                        const groupingProp =
                            scope === "TEAM" ? "team_id" : "user_id";

                        if (!carry[variableValue[groupingProp]])
                            carry[variableValue[groupingProp]] = [];
                        return {
                            ...carry,
                            [variableValue[groupingProp]]: [
                                ...carry[variableValue[groupingProp]],
                                variableValue,
                            ].sort((a, b) =>
                                a.time_index > b.time_index ? 1 : -1,
                            ),
                        };
                    }, {});

                const sortedKeys = Object.keys(scopedValues).sort((a, b) => {
                    const value1 = scopedValues[a][scopedValues[a].length - 1];
                    const value2 = scopedValues[b][scopedValues[b].length - 1];

                    return value1.numerical_value > value2.numerical_value
                        ? -1
                        : 1;
                });

                // if (sortedKeys.length === 0) return { ...leaderBoard };
                let headerCells: ContentBlockShape[] = [];
                if (sortedKeys.length > 1) {
                    const firstKey = sortedKeys[0];
                    let headerCells: ContentBlockShape[] = scopedValues[
                        firstKey
                    ].map((variableValue) => {
                        return {
                            content_block_type:
                                ContentBlockType["Table Header Cell"],
                            content: (variableValue.time_index + 1).toString(),
                        };
                    });
                    headerCells = [
                        {
                            content_block_type:
                                ContentBlockType["Table Header Cell"],
                            content: "Time Horizon",
                        },
                        ...headerCells,
                    ];
                } else {
                    headerCells = [
                        {
                            content_block_type:
                                ContentBlockType["Table Header Cell"],
                            content: scope === "TEAM" ? "Team" : "User",
                            weight: 0,
                        },
                        {
                            content_block_type:
                                ContentBlockType["Table Header Cell"],
                            content: values?.[0]?.label || "",
                            weight: 1,
                        },
                    ];
                }

                return {
                    ...leaderBoard,
                    contentBlocks: [
                        {
                            content_block_type: ContentBlockType["Table Head"],
                            contentBlocks: [
                                {
                                    content_block_type:
                                        ContentBlockType["Table Row"],
                                    contentBlocks: headerCells,
                                },
                            ],
                            weight: 0,
                        },
                        {
                            content_block_type: ContentBlockType["Table Body"],
                            contentBlocks: sortedKeys.map((key, index) => {
                                return {
                                    content_block_type:
                                        ContentBlockType["Table Row"],
                                    contentBlocks: [
                                        {
                                            content_block_type:
                                                ContentBlockType[
                                                    "Table Data Cell"
                                                ],
                                            content: `${(
                                                index + 1
                                            ).toString()}. ${
                                                scope === "TEAM"
                                                    ? scopedValues[key][0]
                                                          .team_name
                                                    : scopedValues[key][0]
                                                          .user_name
                                            }`,
                                        },
                                        ...scopedValues[key].map(
                                            (variableValue) => {
                                                return {
                                                    content_block_type:
                                                        ContentBlockType[
                                                            "Table Data Cell"
                                                        ],
                                                    content:
                                                        formatVariableValue(
                                                            variableValue
                                                                .modelVariable
                                                                .unit,
                                                            variableValue.numerical_value,
                                                            variableValue
                                                                .modelVariable
                                                                .is_integer,
                                                        ),
                                                };
                                            },
                                        ),
                                    ],
                                };
                            }),
                        },
                    ],
                };
            } else {
                return { ...leaderBoard, contentBlocks: [] };
            }
        },
        [_variableValuesByContentBlockId],
    );

    const getContentForSingleVariableValue = useCallback(
        (contentBlock: ContentBlockShape) => {
            const variableValues =
                _variableValuesByContentBlockId[contentBlock.id];

            const timeHorizon =
                contentBlock?.contentBlockModelDataSources?.length &&
                contentBlock?.contentBlockModelDataSources[0]?.timeHorizons
                    ?.length &&
                contentBlock?.contentBlockModelDataSources[0]?.timeHorizons[0];

            if (variableValues && Array.isArray(variableValues)) {
                return variableValues.find((variableValue) =>
                    !timeHorizon
                        ? !variableValue.time_horizon_id
                        : variableValue.time_horizon_id === timeHorizon.id,
                );
            }
        },
        [_variableValuesByContentBlockId],
    );

    return {
        gotVariableValues: _gotVariableValues,
        getFormattedChartData,
        getMappedFormattedChartData,
        getMappedDataForTableRows,
        generateContentBlocksForModelTable,
        generateContentBlocksForLeaderBoard,
        getContentForSingleVariableValue,
    };
}
