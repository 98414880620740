import React, { FC, ReactNode } from "react";
// import useDimensions from "react-use-dimensions";
import { ThemeObject } from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";
import { cn } from "@/util";

type StyledVideoProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    videoUrls: { [index: string]: string };
    contentBlockId: string;
};

function getExtension(url: string) {
    const extensionArray = url.split("").reverse().join("").split(".");
    return extensionArray.length
        ? extensionArray[0].split("").reverse().join("")
        : "";
}

export const StyledVideo: FC<StyledVideoProps> = ({
    theme,
    videoUrls,
    contentBlockId,
    onClick,
    onHover,
}) => {

    const themeWithAs = {
        ...theme,
        as: "div",
    };
    return (
        <StyledUniversalDiv
            {...themeWithAs}
            data-content-block-id={contentBlockId}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
        >
            <video controls={true} className={cn(theme?.tailwindClasses)} key={Object.values(videoUrls)[0]}>
                {Object.values(videoUrls)?.map((source) => (
                    <source
                        key={source}
                        src={source}
                        type={`video/${getExtension(source)}`}
                    />
                ))}
            </video>
        </StyledUniversalDiv>
    );
};

export const defaultVideoTheme: Partial<ThemeObject> = {
    width: ["auto", "auto", "auto", "auto"],
};
