import React, { useCallback } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
} from "@/components/Accordion";
import { ContentBlockShape } from "@/models";
import { useUpdateStyles } from "../../content-block-management/useSaveContentBlock";
import { debounce, useGotContentBlock } from "@/hooks";
import { useContentBlockState } from "../../useContentBlockState";
import PaddingSlider from "./PaddingSlider";
import { useUpdateActiveContentBlock } from "@/hooks/activeContentBlock";
import MarginSlider from "./MarginSlider";
type Props = { contentBlock: ContentBlockShape };

export function DefaultSpacingMenu({ contentBlock }: Props) {
    const { mutateAsync } = useUpdateStyles();
    const { setContentBlock } = useContentBlockState();
    const { gotContentBlock } = useGotContentBlock();
    const { updateActiveContentBlock } = useUpdateActiveContentBlock();

    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            mutateAsync(updatedBlock);
            updateActiveContentBlock(updatedBlock);
            gotContentBlock(updatedBlock);
        }, 100),
        [contentBlock],
    );
    const handleChange = useCallback((newBlock: ContentBlockShape) => {
        setContentBlock(newBlock);
        update(newBlock);
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <Accordion type="single" collapsible={true}>
                <AccordionItem value="padding">
                    <AccordionTrigger>Padding</AccordionTrigger>
                    <AccordionContent>
                        <div className="flex flex-col gap-4">
                            <PaddingSlider
                                contentBlock={contentBlock}
                                handleChange={handleChange}
                            />
                        </div>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="margin">
                    <AccordionTrigger>Margin</AccordionTrigger>
                    <AccordionContent>
                        <div className="flex flex-col gap-4">
                            <MarginSlider
                                contentBlock={contentBlock}
                                handleChange={handleChange}
                            />
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>

            {/* <Alignment
                contentBlock={contentBlock}
                update={handleChange}
            /> */}
        </div>
    );
}
