import { BaseModel, IBaseModel } from "./BaseModel";
import { Option, OptionShape } from "./Option";
import { ModelVariable } from "./ModelVariable";
import {
    OptionContainer,
    SelectionContainer,
} from "@/styles/themes/Question/drag-and-drop/types";

export enum PromptType {
    "Multiple Choice" = "MULTIPLE_CHOICE",
    "Multiple Select" = "MULTIPLE_SELECT",
    "Numerical Input" = "NUMERICAL_INPUT",
    "Numerical Slider" = "NUMERICAL_SLIDER",
    "Rank Order" = "RANK_ORDER",
    "Toggle Switch" = "TOGGLE_SWITCH",
    "Long Text" = "LONG_TEXT",
    "Short Text" = "SHORT_TEXT",
    "Dropdown List" = "DROPDOWN_LIST",
    "Drag and Drop" = "DRAG_AND_DROP",
    "Timeline" = "TIMELINE",
}

export const promptTypes = Object.keys(PromptType).reduce((carry, key) => {
    return { ...carry, [key]: PromptType[key] };
}, {});

export type PromptShape = IBaseModel & {
    content: string;
    prompt_type: PromptType;
    content_block_id: string;
    min: number;
    max: number;
    increment: number;
    is_required: boolean;
    options: OptionShape[];
    scope: string;
    weight: number;
    has_model_effects?: boolean;
    selectionDataModelVariables?: ModelVariable[];
    connectionModelVariables?: ModelVariable[];
    selectionContainers: SelectionContainer[];
    selectionContainer: SelectionContainer;
    optionContainers: OptionContainer[];
    round_id?: string;
    time_horizon_id?: string;
    default_numeric_value?: number;
};

export class Prompt extends BaseModel implements PromptShape {
    constructor(data = null) {
        super();
        if (data) {
            this.setUp(data);
        }
    }

    setUp(data) {
        if (data) {
            for (let prop in data) {
                this[prop] = data[prop];
            }
        }
    }

    public static CLASSNAME: string = "Prompt";
    public static END_POINT: string = "/api/prompts/";

    content: string = "";
    prompt_type: PromptType = PromptType["Multiple Choice"];
    content_block_id: string = "";
    min: number = 0;
    max: number = 100;
    increment: number = 1;
    is_required: boolean = true;
    options: Option[] = [];
    scope: string = "Participant";
    weight: number = 0;

    has_model_effects?: boolean;
    selectionDataModelVariables?: ModelVariable[];
    connectionModelVariables?: ModelVariable[];
    selectionContainers: SelectionContainer[];
    selectionContainer: SelectionContainer;
    optionContainers: OptionContainer[];
    default_numeric_value?: number;
}
