import React, { useState } from "react";

import { ContentBlockShape } from "@/models";
import { GripVertical } from "lucide-react";
import { AddRowButtons } from "./AddRowButtons";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import {
    Popover,
    PopoverContent,
    PopoverPortal,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { DeleteRowButton } from "./DeleteRowButton";
import THSwitch from "./THSwitch";

type Props = {
    tr: ContentBlockShape;
    row: number;
    listeners?: any;
    isDragging?: boolean;
};

export default function RowEditor({ tr, row, listeners, isDragging }: Props) {
    const [isShown, setIsShown] = useState(false);
    const trEl = useGetElementAsync(
        `tr[data-tr-id="${tr.id}"]`,
    ) as HTMLTableRowElement;

    if (!trEl) {
        console.log("trEl is null", tr.id);
        return null;
    }
    
    return (
        <>
            {!!isShown && (
                <div
                    className="borde pointer-events-none absolute bottom-0 left-0 top-0 z-1 border
                        border-sky-300"
                    style={{ width: trEl.clientWidth }}
                />
            )}
            <div
                className="cursor:pointer popover-wrapper group/drag-handle absolute left-0 top-1/2 z-51
                    flex flex-row-reverse items-center opacity-0 transition-all -translate-x-1/2
                    -translate-y-1/2 transform group-hover:opacity-100"
            >
                <Popover onOpenChange={setIsShown} open={isShown}>
                    <PopoverTrigger
                        className="trigger group/drag-handle relative z-[99999] flex h-8 w-5 items-center
                            justify-center rounded-md bg-white text-slate-600 shadow transition-all
                            hover:bg-sapien-blue hover:text-white hover:shadow-sm"
                        onPointerDown={(e) => {
                            e.stopPropagation();
                            setIsShown((prev) => !prev);
                        }}
                    >
                        <GripVertical className="h-6" />

                        <button
                            className="absolute inset-0"
                            {...listeners}
                        ></button>
                    </PopoverTrigger>
                    <PopoverPortal
                        container={
                            (trEl?.children?.item(0) as HTMLTableCellElement) ||
                            trEl
                        }
                    >
                        <PopoverContent
                            asChild={true}
                            className="bg-popover text-popover-foreground data-[state=open]:animate-in
                                data-[state=closed]:animate-out data-[state=closed]:fade-out-0
                                data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
                                data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2
                                data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2
                                data-[side=top]:slide-in-from-bottom-2 z-[9999999] -mt-6 ml-6 rounded-md border
                                bg-white shadow-md outline-none data-[is-dragging=true]:opacity-0"
                            align="start"
                            data-is-dragging={isDragging}
                        >
                            <div className="flex w-64 flex-col justify-center p-2 text-sm">
                                <AddRowButtons tr={tr} row={row} />
                                <DeleteRowButton tr={tr} />
                                {row === 0 && <THSwitch tr={tr} />}
                            </div>
                        </PopoverContent>
                    </PopoverPortal>
                </Popover>
                <div
                    data-is-shown={isShown}
                    data-is-dragging={isDragging}
                    className="-tranlsate-y-full pointer-events-none absolute left-1/2 top-full mt-2 flex w-32
                        flex-col items-center justify-center space-y-1 rounded-md bg-black p-2 text-sm
                        text-white opacity-0 transition-all -translate-x-1/2
                        group-hover/drag-handle:opacity-100 group-hover/drag-handle:translate-y-0
                        data-[is-dragging=true]:!opacity-0 data-[is-shown=true]:!opacity-0"
                >
                    <p>Drag to reorder</p>
                    <p>Click for menu</p>
                </div>
            </div>
        </>
    );
}
