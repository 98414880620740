import { useFormatVariableValue } from "@/hooks";
import { useGetAllElementsAsync } from "@/hooks/useGetElementAsync";
import { sapienAxios } from "@/inertia-utils/hooks";
import { ModelVariableScope, ModelVariableUnit } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useQuery } from "@tanstack/react-query";

async function getModelValue(
    modelVariableIds: string[],
    timeHorizonIds: string[],
) {
    const { data } = await sapienAxios.post<{
        values: {
            numerical_value: number;
            is_integer: boolean;
            unit: ModelVariableUnit;
            time_horizon_id: string;
            model_variable_id: string;
            scope: ModelVariableScope;
            uses_time: boolean;
        }[];
    }>("get-model-value", {
        model_variable_ids: modelVariableIds,
        time_horizon_ids: timeHorizonIds,
    } as unknown as (typeof LaravelRequestBodyShapes)["get-model-value"]);

    return data;
}

export function useRichTextModelQuantities() {



    const formatVariableValue = useFormatVariableValue();

    const childElements = useGetAllElementsAsync("[data-model-variable-id]");
    const timeHorizonIds = [];
    const modelVariableIds = [];

    const ids = new Set<string>();
    if (!!childElements) {
        for (const childElement of Array.from(childElements)) {
            const modelVariableId = childElement.getAttribute(
                "data-model-variable-id",
            );
            const timeHorizonId = childElement.getAttribute(
                "data-time-horizon-id",
            );
            modelVariableIds.push(modelVariableId);
            timeHorizonIds.push(timeHorizonId || null);
            const key = !!timeHorizonId ? `${modelVariableId}_${timeHorizonId}` : modelVariableId;
            ids.add(key);
        }
    }

    const { data: modelValues, isLoading } = useQuery({
        queryKey: ["model-value", childElements?.length, JSON.stringify(ids)],
        queryFn: () => getModelValue(modelVariableIds, timeHorizonIds),
        enabled: !!modelVariableIds?.length,
        select: (data) => {
            return data.values.reduce((acc, value) => {
                const key = value.time_horizon_id
                    ? value.model_variable_id + "_" + value.time_horizon_id
                    : value.model_variable_id;

                return {
                    ...acc,
                    [key]: formatVariableValue(
                        value.unit,
                        value.numerical_value,
                        value.is_integer,
                    ),
                };
            }, {});
        },
    });

    return { modelValues, isLoading, elements: childElements };
}
