import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, OptionShape, PromptShape } from "@/models";
import { useMutation } from "@tanstack/react-query";
import { useContentBlockState } from "../useContentBlockState";

export function useUpdateOption() {
    return useMutation({
        mutationFn: async ({content_block_id, content}: {
            content_block_id: string;
            content: string;
        }) => {
            const { data } = await sapienAxios.put<
                { contentBlock: ContentBlockShape },
                "e-learning.options.update"
            >("e-learning.options.update", {
                content_block_id,
                content
            });

            return data?.contentBlock;
        },
    });
}

export function useSaveOption() {
    const { setContentBlock } = useContentBlockState();
    return useMutation({
        mutationFn: async (prompt: PromptShape) => {
            const { data } = await sapienAxios.post<
                { contentBlock: ContentBlockShape },
                "e-learning.options.store"
            >("e-learning.options.store", {
                weight: prompt?.options?.length || 0,
                prompt_id: prompt?.id,
            });

            return data?.contentBlock;
        },
        onSuccess(contentBlock) {
            setContentBlock(contentBlock);
        },
    });
}

export function useSaveOptionScoring() {
    const { setContentBlock } = useContentBlockState();
    return useMutation({
        mutationFn: async (option: OptionShape) => {
            const { data } = await sapienAxios.put<
                { contentBlock: ContentBlockShape },
                "e-learning.scoring.update"
            >(
                "e-learning.scoring.update",
                {
                    id: option?.id,
                    selected_numerical_value: option?.selected_numerical_value,
                    unselected_numerical_value:
                        option?.unselected_numerical_value,
                },
                { option: option?.id },
            );

            return data?.contentBlock;
        },
        onSuccess(contentBlock) {
            setContentBlock(contentBlock);
        },
    });
}
