import React, { useState } from "react";

import { ContentBlockShape } from "@/models";
import { EditorFormInputRange } from "@/styles/editor-components/StyledEditorComponents";
import { ChevronDownIcon } from "lucide-react";

type Props = {
    contentBlock: ContentBlockShape;
    handleChange: (contentBlock: ContentBlockShape) => void;
};

const topMarginClassNames = [
    "mt-0",
    "mt-1",
    "mt-2",
    "mt-3",
    "mt-4",
    "mt-5",
    "mt-6",
    "mt-8",
    "mt-10",
    "mt-12",
    "mt-16",
    "mt-20",
    "mt-24",
    "mt-32",
    "mt-40",
    "mt-48",
    "mt-56",
    "mt-64",
    "mt-72",
    "mt-80",
    "mt-96",
];

const bottomMarginClassNames = [
    "mp-0",
    "mp-1",
    "mp-2",
    "mp-3",
    "mp-4",
    "mp-5",
    "mp-6",
    "mp-8",
    "mp-10",
    "mp-12",
    "mp-16",
    "mp-20",
    "mp-24",
    "mp-32",
    "mp-40",
    "mp-48",
    "mp-56",
    "mp-64",
    "mp-72",
    "mp-80",
    "mp-96",
];

const leftMarginClassNames = [
    "ml-0",
    "ml-1",
    "ml-2",
    "ml-3",
    "ml-4",
    "ml-5",
    "ml-6",
    "ml-8",
    "ml-10",
    "ml-12",
    "ml-16",
    "ml-20",
    "ml-24",
    "ml-32",
    "ml-40",
    "ml-48",
    "ml-56",
    "ml-64",
    "ml-72",
    "ml-80",
    "ml-96",
];

const rightMarginClassNames = [
    "mr-0",
    "mr-1",
    "mr-2",
    "mr-3",
    "mr-4",
    "mr-5",
    "mr-6",
    "mr-8",
    "mr-10",
    "mr-12",
    "mr-16",
    "mr-20",
    "mr-24",
    "mr-32",
    "mr-40",
    "mr-48",
    "mr-56",
    "mr-64",
    "mr-72",
    "mr-80",
    "mr-96",
];

export default function MarginSlider({ contentBlock, handleChange }: Props) {
    const topMarginClassName = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("mt-"),
    );
    const bottomMarginClassName = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("mb-"),
    );
    const leftMarginClassName = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("ml-"),
    );
    const rightMarginClassName = contentBlock.theme?.tailwindClasses?.find(
        (className) => className.includes("mr-"),
    );

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                        <label>Top Margin</label>
                        <EditorFormInputRange
                            className="w-full text-sapien-blue"
                            type="range"
                            defaultValue={
                                !!topMarginClassName
                                    ? topMarginClassNames.indexOf(
                                          topMarginClassName,
                                      ) || 0
                                    : 0
                            }
                            onChange={(e) => {
                                handleChange({
                                    ...contentBlock,
                                    theme: {
                                        ...contentBlock.theme,
                                        tailwindClasses: [
                                            ...(contentBlock.theme.tailwindClasses?.filter(
                                                (className: string) =>
                                                    !className.includes("mt-"),
                                            ) || []),
                                            topMarginClassNames[e.target.value],
                                        ],
                                    },
                                });
                            }}
                            min={0}
                            max={topMarginClassNames.length - 1}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Bottom Margin</label>
                        <EditorFormInputRange
                            type="range"
                            defaultValue={
                                !!bottomMarginClassName
                                    ? bottomMarginClassNames.indexOf(
                                          bottomMarginClassName,
                                      ) || 0
                                    : 0
                            }
                            onChange={(e) => {
                                handleChange({
                                    ...contentBlock,
                                    theme: {
                                        ...contentBlock.theme,
                                        tailwindClasses: [
                                            ...(contentBlock.theme?.tailwindClasses?.filter(
                                                (className) =>
                                                    !className.includes("mb-"),
                                            ) || []),
                                            bottomMarginClassNames[
                                                e.target.value
                                            ],
                                        ],
                                    },
                                });
                            }}
                            min={0}
                            max={bottomMarginClassNames.length - 1}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Left Margin</label>
                        <EditorFormInputRange
                            type="range"
                            defaultValue={
                                !!leftMarginClassName
                                    ? leftMarginClassNames.indexOf(
                                          leftMarginClassName,
                                      ) || 0
                                    : 0
                            }
                            onChange={(e) => {
                                handleChange({
                                    ...contentBlock,
                                    theme: {
                                        ...contentBlock.theme,
                                        tailwindClasses: [
                                            ...(contentBlock.theme?.tailwindClasses?.filter(
                                                (className) =>
                                                    !className.includes("ml-"),
                                            ) || []),
                                            leftMarginClassNames[
                                                e.target.value
                                            ],
                                        ],
                                    },
                                });
                            }}
                            min={0}
                            max={leftMarginClassNames.length - 1}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Right Margin</label>
                        <EditorFormInputRange
                            type="range"
                            defaultValue={
                                !!rightMarginClassName
                                    ? rightMarginClassNames.indexOf(
                                          rightMarginClassName,
                                      ) || 0
                                    : 0
                            }
                            onChange={(e) => {
                                handleChange({
                                    ...contentBlock,
                                    theme: {
                                        ...contentBlock.theme,
                                        tailwindClasses: [
                                            ...(contentBlock.theme?.tailwindClasses?.filter(
                                                (className) =>
                                                    !className.includes("mr-"),
                                            ) || []),
                                            rightMarginClassNames[
                                                e.target.value
                                            ],
                                        ],
                                    },
                                });
                            }}
                            min={0}
                            max={rightMarginClassNames.length - 1}
                        />
                    </div>
        </div>
    );
}
