import React, { FC } from "react";
import { QuestionContentBlockFormField } from "./QuestionContentBlockFormField";
import { QuestionContentBlockFormFieldTheme } from "./types";
import { StyledUniversalDiv } from "../Universal";
import { ColorScheme, Prompt } from "../../../models";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { QuestionContentBlockFormFieldWrapper } from "./QuestionContentBlockFormFieldWrapper";
import styled from "styled-components";
import { useGetContentBlockWithChildren } from "@/Pages/DesignStudio/Design/useContentBlockState";

const QuestionTheme = styled(StyledUniversalDiv)`
    &:has(.drag-and-drop-container) {
        width: 100% !important;
        max-width: unset;
    }
`;

function QuestionContentBlockWrapper({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    zIndex,
    hasOthersSelections,
}: {
    theme: any;
    children: any;
    contentBlockId: string;
    zIndex: number;
    hasOthersSelections?: boolean;
} & ContentBlockEvents) {
    const questionBlock = useGetContentBlockWithChildren(contentBlockId);

    return (
        <QuestionTheme
            pt={hasOthersSelections ? 6 : 5}
            width={theme.width || "100%"}
            maxWidth={questionBlock?.theme?.variant === "cards" ? "" : "960px"}
            bg={"transparent"}
            data-content-block-id={contentBlockId}
            data-question-id={contentBlockId}
            onClick={onClick}
            onMouseMove={onHover}
            position="relative"
            zIndex={zIndex}
            color={theme?.color || "black"}
            className={`z-[${zIndex}] group/QUESTION relative @container question-wrapper`}
            fontSize={theme?.fontSize || "16px"}
            fontWeight={theme?.fontWeight || "400"}
            lineHeight={theme?.lineHeight || "1.5"}
        >
            {children}
        </QuestionTheme>
    );
}

type StyledQuestionProps = ContentBlockEvents & {
    promptId: string;
    prompt: Prompt;
    palette?: ColorScheme;
    theme?: QuestionContentBlockFormFieldTheme;
    contentBlockId: string;
    zIndex: number;
    children?: JSX.Element;
    optionRenderMap: { [index: string]: JSX.Element | JSX.Element[] };
    isInDesignContext: boolean;
};

export const StyledQuestion: FC<StyledQuestionProps> = ({
    promptId,
    prompt,
    theme,
    contentBlockId,
    onClick,
    onHover,
    children,
    zIndex,
    palette,
    optionRenderMap,
    isInDesignContext,
    title,
}) => {
    return (
        <QuestionContentBlockWrapper
            theme={theme}
            contentBlockId={contentBlockId}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Question"}
            zIndex={zIndex}
            hasOthersSelections={!!children}
            data-question-id={contentBlockId}
        >
            {!!isInDesignContext && !prompt?.id ? (
                <p className="cursor-pointer pb-7 text-center text-gray-500">
                    Click to create prompt
                </p>
            ) : (
                <QuestionContentBlockFormFieldWrapper
                    promptText={prompt.content}
                    palette={palette}
                >
                    <QuestionContentBlockFormField
                        theme={theme}
                        key={promptId}
                        promptId={promptId}
                        prompt={prompt}
                        palette={palette}
                        optionRenderMap={optionRenderMap}
                        isInDesignContext={isInDesignContext}
                        contentBlockId={contentBlockId}
                    />
                </QuestionContentBlockFormFieldWrapper>
            )}

            {children}
        </QuestionContentBlockWrapper>
    );
};

//When updating this theme, see the create method of QuestionDesignController.php
export const defaultQuestionTheme: Partial<QuestionContentBlockFormFieldTheme> =
    {
        palette: "light",
        spaced: "spaced",
        highLightStyle: "color",
        highlightColor: "#9AE6B4",
        checkMarkColor: "#2563eb",
        bordered: "bordered",
        width: ["auto", "auto", "auto", "auto"],
        color: "#000000ff",
        sliderValueType: "tooltip",
        fontSize: ["16px", "16px", "16px", "16px"],
        fontWeight: "400",
        lineHeight: "1.5",
    };
