import React, { FC, ReactNode, useEffect, useState } from "react";
import { ThemeObject } from "../ThemeObjects";
import { StyledUniversalDiv } from "./Universal";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { useGetContentBlockWithChildren } from "@/Pages/DesignStudio/Design/useContentBlockState";
import StyleDropdown from "@/Pages/DesignStudio/Design/content-block-editing/style-dropdowns/StyleDropdown";
import { themeToStyleVariables } from "@/tailwind-colors/themePropertyToStyleVariable";
import { cn } from "@/util";
import { router } from "@inertiajs/react";
import { useAtom } from "jotai";
import { directionAtom } from "@/hooks/useContentBlockBehaviors";

type StyledPageProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId: string;
};

export const StyledPage: FC<StyledPageProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    isInEditorContext,
    title,
}) => {
    let navBarHeight = 0;
    const participantTopNav = document.querySelector(".participant-top-nav");
    const navBar = document.querySelector(".navbar");
    const editorBar = document.querySelector(".EDITOR-NAV-MENU");
    if (navBar || participantTopNav || editorBar) {
        navBarHeight = [navBar, participantTopNav, editorBar].reduce(
            (carry, element) => {
                if (element) {
                    return carry + element.getBoundingClientRect().height;
                }
                return carry;
            },
            0,
        );
    }

    const contentBlock = useGetContentBlockWithChildren(contentBlockId);
    const styleVariables = themeToStyleVariables(theme);

    const [direction] = useAtom(directionAtom);
    const [transitioning, setTransitioning] = useState(false);
    // useEffect(() => {
    //     router.on("start", () => setTransitioning(true));
    //     router.on("finish", () =>{setTimeout(() => setTransitioning(false), 1000)});
    // }, []);

    return (
        <StyledUniversalDiv
            width={"100%"}
            //see editorSizes in EditorContainer.tsx
            color={theme.color}
            minHeight={`calc(100vh - ${navBarHeight}px)`}
            backgroundColor={theme.backgroundColor}
            pt={"2em"}
            pb={"2em"}
            onClick={"function" === typeof onClick ? onClick : (evt) => {}}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "Page"}
            style={styleVariables}
            className={`${cn("relative", theme.tailwindClasses)} transition-all duration-300
            data-[transitioning=true]:data-[direction=backward]:translate-x-full
            data-[transitioning=true]:data-[direction=forward]:-translate-x-full ease-in-out`}
            data-direction={direction}
            data-transitioning={transitioning}
        >
            {!!isInEditorContext && (
                <div className="absolute left-1/2 top-0 z-[99999] translate-x-full">
                    <StyleDropdown contentBlock={contentBlock} />
                </div>
            )}
            <StyledUniversalDiv
                {...theme}
                backgroundColor={"transparent"}
                className="page-child"
            >
                {children}
            </StyledUniversalDiv>
        </StyledUniversalDiv>
    );
};

export const defaultPageTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffff",
    pt: 0,
    pb: 0,
    px: 0,
    my: 0,
    mx: 0,
};
