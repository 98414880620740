import { ContentBlockType } from "../../models";
import { ConfigComponentType, defaultCardTheme } from "../../styles";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";

export const CardConfig: ContentBlockConfig & {
    fillStyle: "outline" | "elevated" | "filled" | "none";
    imagePlacement: "none" | "left" | "right" | "center";
    palette: string;
} = {
    typeKey: "Card",
    type: ContentBlockType.Card,
    addableChildBlocks: [
        ContentBlockType.Box,
        ContentBlockType["Rich Text"],
        ContentBlockType["Model Value Display"],
        ContentBlockType.Image,
        ContentBlockType.Modal,
        ContentBlockType.Accordion
    ],
    requiredChildBlocks: [
        ContentBlockType.Box,
        ContentBlockType["Rich Text"],
        ContentBlockType.Image,
    ],
    defaultTheme: defaultCardTheme,
    isCollectionType: true,
    fillStyle: "elevated",
    imagePlacement: "center",
    palette: "light",
    availableBehaviors: ["ShowModalContentBlock"],
    variantOptions: [
        {
            label: "Fill Style",
            themeObjectKey: "fillStyle",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                outline: "outline",
                elevated: "elevated",
                filled: "filled",
                none: "none",
            },
        },
        {
            label: "Image Placement",
            themeObjectKey: "imagePlacement",
            configComponentType: ConfigComponentType.FormSelectEnumType,
            optionObject: {
                none: "none",
                left: "left",
                right: "right",
                center: "center",
            },
        },
    ],
};
