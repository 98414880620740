import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, ContentBlockType, RichText } from "@/models";
import { useMutation } from "@tanstack/react-query";
import { useContentBlockState } from "../useContentBlockState";
import { useIsAddingContentBlock } from "../content-block-editing/useIsAddingContentBlock";
import { atom, useAtom } from "jotai";
import { useCallback } from "react";
import { updatedContentBlockIdAtom } from "./useSaveContentBlock";
import { useDiscardActiveContentBlock, useUpdateActiveContentBlock } from "@/hooks/activeContentBlock";

// function getBlockWithNoParent(
//     block: ContentBlockShape,
//     contentBlocks: ContentBlockShape[],
// ) {
//     if (block.parent_content_block_id) {
//         const parentBlock = contentBlocks.find(
//             ({ id }) => id === block.parent_content_block_id,
//         );
//         return getBlockWithNoParent(parentBlock, contentBlocks);
//     }
//     return block;
// }

async function saveContentBlock({content_block_id, rich_text}: {content_block_id: string, rich_text: RichText}) {
        // const updatedBlock = getBlockWithNoParent(
        //     contentBlock,
        //     Object.values(storedBlocks),
        // );

        // setUpdatedContentBlockId(updatedBlock?.id);
        const { data } = await sapienAxios.put<
            {
                savedContentBlock: ContentBlockShape;
            },
            "creator.design.content-blocks.rich-text"
        >(
            "creator.design.content-blocks.rich-text",
            {
                rich_text,
                content_block_id,
            },
            {
                contentBlock: content_block_id,
            },
        );
        const { savedContentBlock,  } = data;
        return { savedContentBlock };
   
}


export function useSaveRichText() {
    const { setContentBlock } = useContentBlockState();
    const { setIsAddingBlock } = useIsAddingContentBlock();
    const [_, setUpdatedContentBlockId] = useAtom(updatedContentBlockIdAtom);
    const { contentBlocks: storedBlocks } = useContentBlockState();
    const { updateActiveContentBlock } = useUpdateActiveContentBlock();
    const saveBlock = useCallback(
        (contentBlock: {content_block_id: string, rich_text: RichText}) => {
            
            return saveContentBlock(contentBlock);
        },
        [storedBlocks],
    );

    return useMutation({
        mutationFn: saveBlock,
        onSuccess: ({  savedContentBlock }) => {
            setUpdatedContentBlockId(false);
            setIsAddingBlock(false);
            updateActiveContentBlock(savedContentBlock);
            setContentBlock({...savedContentBlock, contentBlocks:[]});
            return savedContentBlock;
        },
        onError: (error) => {
            console.error("Error saving content block", error);
        },
    });
}
 