import { ContentBlockShape } from "@/models";
import { useAtom, atom } from "jotai";

export const activeContentBlockAtom = atom<ContentBlockShape | false>(false);
// export const activeContentBlockAtom = atom<ContentBlockShape | false>(false);
activeContentBlockAtom.debugLabel = "activeContentBlockAtom";

export function useGetActiveContentBlock() {
    const [activeContentBlock, setActiveContentBlock] = useAtom(
        activeContentBlockAtom
    );

    //shape to match existing API expected shape
    return {
        data: activeContentBlock as ContentBlockShape,
        setActiveContentBlock,
    };
}
