import { BubbleMenu, Editor } from "@tiptap/react";
import React, { useRef, useState } from "react";
import Menu from "./Menu";
import { ContentBlock } from "@/models";

type Props = {
    editor: Editor;
    showColorPicker?: boolean;
    isOpen?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    contentBlockId?: string;
};

export function RichTextTooltipMenu({
    editor,
    showColorPicker,
    isOpen = false,
    setIsOpen,
    contentBlockId,
}: Props) {
    const openRef = useRef<boolean>(false);
    openRef.current = isOpen;

    return (
        <BubbleMenu
            editor={editor}
            tippyOptions={{
                duration: 100,
                maxWidth: 412,
                onHidden: () => {
                    setIsOpen?.(false);
                },
                followCursor: true,
                interactive: true,
                
            }}
            className="relative z-[9999] flex items-center justify-center bg-white"
        >
            <Menu
                editor={editor}
                showColorPicker={showColorPicker}
                contentBlockId={contentBlockId}
            />
        </BubbleMenu>
    );
}
