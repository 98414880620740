import React, { useCallback, useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTrigger,
} from "@/components/Dialog";
import { ImageIcon, ImagePlusIcon, XIcon } from "lucide-react";
import FileSelector from "@/Pages/Admin/editor/uploads/FileSelector";
import ImageOrVideoSearch from "@/Pages/Admin/editor/uploads/ImageOrVideoSearch";
import { Switch } from "@/components/Switch";
import { useUploadStore } from "@/hooks";
import { createPortal } from "react-dom";

type Props = {
    imageUrl: string;
    save: (imageUrl: string) => Promise<any>;
    saving: boolean;
    element?: HTMLElement;
    children?: React.ReactNode;
};

export default function ImageModal({
    imageUrl,
    save,
    saving,
    element,
    children,
}: Props) {
    const [imageSourceType, setImageSourceType] = useState<"upload" | "api">(
        "api",
    );

    const [open, setOpen] = useState<boolean>(false);

    const { uploadedFileUrl } = useUploadStore();

    const handleSave = useCallback(async () => {
        await save(uploadedFileUrl);
        setOpen(false);
    }, [save, uploadedFileUrl]);

    const [hovered, setHovered] = useState(false);

    return (
        <>
            {!!element &&
                createPortal(
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (!hovered) return;
                            setOpen(true);
                        }}
                        className="pointer-events-none absolute left-1/2 top-1/2 flex w-full items-center
                            justify-center rounded-full p-4 text-transparent transition-all duration-300
                            -translate-x-1/2 -translate-y-1/2 group-hover/image-editor:pointer-events-auto
                            group-hover/image-editor:bg-black/50 group-hover/image-editor:text-white"
                        aria-label="Image Editor"
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    >
                        <ImagePlusIcon className="h-[10%] min-h-[2rem] w-[10%] min-w-[2rem] transition-colors" />
                    </button>,
                    element,
                )}
            <Dialog open={open} onOpenChange={setOpen}>
                {!element && (
                    <DialogTrigger asChild>
                        <button
                            className="flex items-center gap-2"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpen(true);
                            }}
                        >
                            {children}
                        </button>
                    </DialogTrigger>
                )}
                <DialogContent className="w-[90vh] max-w-screen-xl text-white">
                    <div className="flex w-full flex-col items-center gap-4 rounded-lg bg-[#111928] p-6 text-white">
                        <h3 className="flex w-full items-center justify-between p-6 text-lg font-bold text-white">
                            <span>Image Editor</span>
                            {!!imageUrl && (
                                <RemoveImage removeImage={() => save("")} />
                            )}
                        </h3>

                        <div className="flex w-full gap-2 pl-6">
                            <label
                                htmlFor="upload-switch"
                                className="flex items-center gap-2"
                            >
                                Search for millions of images
                            </label>
                            <Switch
                                className="border-sapien-blue data-[state=checked]:bg-gray-500
                                    data-[state=unchecked]:bg-gray-400"
                                checked={imageSourceType === "upload"}
                                onCheckedChange={(checked) =>
                                    setImageSourceType(
                                        checked ? "upload" : "api",
                                    )
                                }
                            />
                            <label htmlFor="upload-switch">
                                Upload an image
                            </label>
                        </div>

                        <div
                            className="flex max-h-[75vh] w-full items-center justify-between overflow-y-auto px-6
                                scrollbar scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full scrollbar-w-2"
                        >
                            {imageSourceType === "upload" && (
                                <FileSelector imageUrl={imageUrl} />
                            )}
                            {imageSourceType === "api" && (
                                <ImageOrVideoSearch
                                    mediaType={"backgroundImage"}
                                />
                            )}
                        </div>
                        <div className="flex w-full justify-end">
                            <button
                                disabled={saving}
                                data-saving={saving}
                                onClick={handleSave}
                                className="rounded-md bg-blue-500 px-4 py-2 text-white transition-colors
                                    data-[saving=true]:animate-pulse hover:bg-blue-700 disabled:opacity-50"
                            >
                                {saving ? "Saving..." : "Save"}
                            </button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

function RemoveImage({ removeImage }: { removeImage: () => void }) {
    return (
        <button
            onClick={removeImage}
            className="flex items-center gap-2 rounded-md bg-red-500 p-2 text-white transition-colors
                hover:bg-red-600"
        >
            <XIcon className="h-4 w-4" />
            <span>Remove Image</span>
        </button>
    );
}
