import { useContentBlockBehaviors } from "@/hooks/useContentBlockBehaviors";
import React, { FC, ReactNode, useMemo } from "react";
import styled from "styled-components";
import { ThemeObject, JustifyContent } from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalButton, StyledUniversalDiv } from "./Universal";
import { cn } from "@/util";
import { themeToStyleVariables } from "@/tailwind-colors/themePropertyToStyleVariable";

type ButtonTheme = ThemeObject & {
    iconPlacement: "left" | "right" | "center" | "none";
    buttonShape: "sharp" | "soft" | "pill" | "circle";
    size: "xs" | "sm" | "md" | "lg" | "xl";
    alignment: "right" | "left" | "center";
    fillStyle: "filled" | "outline" | "ghost";
    activeBackgroundColor: string;
    activeColor: string;
    activeBorderColor: string;
    hoverBackgroundColor: string;
    hoverColor: string;
    color: string;
    backgroundColor: string;
    borderColor: string;
    disabledBorderColor: string;
    disabledBackgroundColor: string;
    disabledColor: string;
};

type StyledButtonProps = ContentBlockEvents & {
    theme: Partial<ButtonTheme>;
    children?: ReactNode;
    contentBlockId?: string;
    isSubmitting?: boolean;
    isInvalid?: boolean;
};

export const ButtonGroup = styled(StyledUniversalDiv)`
    border-radius: 0.25em;
    display: inline-flex;
    > * {
        color: black;
        border-left: 1px solid black;
        border-radius: 0;
        &:first-child {
            border-left: none;
            border-top-left-radius: 0.25em !important;
            border-bottom-left-radius: 0.25em !important;
        }
        &:last-child {
            border-top-right-radius: 0.25em !important;
            border-bottom-right-radius: 0.25em !important;
        }
    }
`;

const Button = styled(StyledUniversalButton)<Partial<ButtonTheme>>`
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    img {
        display: ${(props) => {
            if (props.iconPlacement === "none") return "none";
        }};

        width: ${(props) => {
            if (props.buttonShape === "sharp" && props.size === "xs")
                return "12px";

            if (
                props.buttonShape === "sharp" &&
                (props.size === "sm" || props.size === "md")
            )
                return "14px";

            if (props.buttonShape === "sharp" && props.size === "lg")
                return "16px";

            if (props.buttonShape === "sharp" && props.size === "xl")
                return "18px";
        }};

        height: ${(props) => {
            if (props.buttonShape === "sharp" && props.size === "xs")
                return "12px";

            if (
                props.buttonShape === "sharp" &&
                (props.size === "sm" || props.size === "md")
            )
                return "14px";

            if (props.buttonShape === "sharp" && props.size === "lg")
                return "16px";

            if (props.buttonShape === "sharp" && props.size === "xl")
                return "18px";
        }};

        margin-right: ${(props) => {
            if (props.iconPlacement === "left") return "5px";
        }};
        margin-left: ${(props) => {
            if (props.iconPlacement === "right") return "5px";
        }};
    }

    display: flex;
    align-items: center;

    flex-direction: ${(props) => {
        if (props.iconPlacement === "right") return "row";
        if (props.iconPlacement === "left") return "row-reverse";
    }};

    justify-content: ${(props) => {
        if (props.iconPlacement === "none" || props.iconPlacement === "center")
            return "center";
        return "space-evenly";
    }};

    align-self: ${(props) => {
        if (props.alignment === "right") return "flex-end";
        if (props.alignment === "left") return "flex-start";
        if (props.alignment === "center") return "center";
    }};

    justify-self: ${(props) => {
        if (props.alignment === "right") return "flex-end";
        if (props.alignment === "left") return "flex-start";
        if (props.alignment === "center") return "center";
    }};

    line-height: 150%;

    padding: ${(props) => {
        // Style for rectangle button
        if (props.buttonShape === "sharp" || props.buttonShape === "soft") {
            if (props.size === "xs") return "4px 8px";
            if (props.size === "sm") return "6px 12px";
            if (props.size === "md") return "10px 16px";
            if (props.size === "lg") return "12px 24px";
            if (props.size === "xl") return "14px 48px";
        }

        // Style for square  button
        if (props.size === "xs") return "4px";
        if (props.size === "sm") return "8px";
        if (props.size === "md") return "12px";
        if (props.size === "lg") return "16px";
        if (props.size === "xl") return "20px 20px";
    }};

    border-radius: ${(props) => {
        if (props.buttonShape === "circle" || props.buttonShape === "pill")
            return "50%";

        if (props.buttonShape === "soft") {
            if (props.borderRadius) {
                return props.borderRadius as string;
            }
            return "8px";
        }

        if (props.buttonShape === "sharp") return 0;

        return "0px";
    }};

    //replace with button pseudo states
    background: ${({ backgroundColor, fillStyle }) =>
        fillStyle === "filled" ? backgroundColor : "none"};

    color: ${({ color, backgroundColor, fillStyle }) => {
        if (color) return color;
        if (fillStyle !== "filled" && backgroundColor) return backgroundColor;
        return color;
    }};
    border-style: ${({ fillStyle }) => {
        if (fillStyle === "outline") return "solid";
    }};

    &:focus,
    &:active {
        background: ${({ activeBackgroundColor, backgroundColor }) => {
            if (activeBackgroundColor) return activeBackgroundColor;
            else return backgroundColor;
        }};
        color: ${({ activeColor, color }) => {
            if (activeColor) return activeColor;
            return color;
        }};
        border-color: ${({ fillStyle, activeBorderColor, borderColor }) => {
            if (fillStyle === "outline" && activeBorderColor)
                return activeBorderColor;
            else return borderColor;
        }};
    }

    &:hover {
        background: ${({ hoverBackgroundColor, backgroundColor }) => {
            if (hoverBackgroundColor) return hoverBackgroundColor;
            else return backgroundColor;
        }};
        color: ${({ hoverColor, color }) => {
            if (hoverColor) return hoverColor;
            else return color;
        }};
        border-color: ${({ fillStyle, hoverBorderColor, borderColor }) => {
            if (
                (fillStyle === "outline" || fillStyle === "ghost") &&
                hoverBorderColor
            )
                return hoverBorderColor;
            else return borderColor;
        }};
    }
    &:disabled {
        background: ${({ disabledBackgroundColor, backgroundColor }) => {
            if (disabledBackgroundColor) return disabledBackgroundColor;
            return backgroundColor;
        }};
        color: ${({ disabledColor, color }) => {
            if (disabledColor) return disabledColor;
            else return color;
        }};
        border-color: ${({ disabledBorderColor, fillStyle, borderColor }) => {
            if (fillStyle === "outline" && disabledBorderColor)
                return disabledBorderColor;
            else return borderColor;
        }};

        opacity: ${({ fillStyle }) => {
            if (fillStyle === "outline") return 0.35;
        }};
        cursor: not-allowed;
    }
`;

export const StyledButton: FC<StyledButtonProps> = ({
    theme,
    children,
    contentBlockId,
    isInEditorContext,
    isSubmitting,
    isInvalid,
}) => {
    const filledTheme = { ...defaultButtonTheme, ...theme };
    const fontSize = useMemo(() => {
        if (filledTheme.iconPlacement === "center") return "0px";

        if (filledTheme.fontSize === 5) {
            if (filledTheme.size === "xs" || filledTheme.size === "sm")
                return "12px";
            if (
                filledTheme.size === "md" ||
                filledTheme.size === "lg" ||
                filledTheme.size === "xl"
            )
                return "14px";
        }
        return filledTheme.fontSize;
    }, [filledTheme]);

    const { clickFunction, isProcessing } =
        useContentBlockBehaviors(contentBlockId);
    const isDisabled = isSubmitting || isProcessing;
    const styleVariables = themeToStyleVariables(filledTheme);

    return (
        <>
            <Button
                // disabled={isDisabled || isInvalid}
                as={isInEditorContext ? "div" : "button"}
                {...filledTheme}
                data-content-block-id={contentBlockId}
                type={isInEditorContext ? "button" : "submit"}
                onClick={clickFunction}
                fontWeight={filledTheme.fontWeight || 300}
                fontSize={fontSize}
                disabled={isDisabled || isInvalid}
                className={` relative
                disabled:animate-pulse disabled:cursor-not-allowed ${cn(
                    theme?.tailwindClasses || [],
                )}`}
                style={
                    {
                        display: isInEditorContext ? "inline-flex" : "flex",
                        ...styleVariables,
                    } as React.CSSProperties
                }
            >
                {children}
            </Button>
            {!!isDisabled && (
                <div
                    className="fixed inset-0 z-[999999999] flex h-full w-full animate-pulse items-center
                        justify-center bg-white/50"
                >
                    <div
                        style={{ color: theme?.color }}
                        className="absolute inset-0 flex h-full w-full items-center justify-center"
                    >
                        <svg
                            className="h-12 w-12 animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-80"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className=""
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </div>
                </div>
            )}
        </>
    );
};

export const defaultButtonTheme: Partial<ButtonTheme> = {
    mt: 0,
    mb: 0,
    mr: 0,
    ml: 0,
    // fontFamily: "0",
    // fontSize: 5,
    fontWeight: 4,
    letterSpacing: 4,
    // fontStyle: FontStyle.normal,
    // textTransform: TextTransform.uppercase,
    color: "#000000",
    // opacity: 1,
    width: ["auto"],
    // border: "",
    borderWidth: 1,
    borderRadius: 0,
    borderStyle: "solid",
    cursor: "pointer",
    justifyContent: JustifyContent.center,
    activeBackgroundColor: "#1E429F",
    activeColor: "#FFF",
    activeBorderColor: "#FFF",
    hoverBackgroundColor: "#1A56DB",
    hoverColor: "#FFF",
    hoverBorderColor: "#1A56DB",
    // color: "#FFF",
    backgroundColor: "#1C64F2",
    borderColor: "#1C64F2",
    disabledBorderColor: "rgba(0, 0, 0, 0.16)",
    disabledBackgroundColor: "rgba(0, 0, 0, 0.06)",
    disabledColor: "rgba(0, 0, 0, 0.16)",

    fillStyle: "outline",
    size: "md",
    iconPlacement: "none",
    buttonShape: "soft",
    alignment: "left",
};
