import React, { useCallback, useMemo } from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { cnToArray } from "@/util";
import { debounce } from "@/hooks";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../useContentBlockState";
import ButtonGroup from "../ButtonGroup";
import ColorSelector from "@/components/rich-text/ColorSelector";
import { ColorPickerPopover } from "@/components/color-picker";

function findAccordionItem(contentBlock: ContentBlockShape): ContentBlockShape | undefined {
    if (contentBlock.content_block_type === ContentBlockType.AccordionItem) {
        return contentBlock;
    }

    if (!contentBlock.contentBlocks) {
        return undefined;
    }

    for (const child of contentBlock.contentBlocks) {
        const found = findAccordionItem(child);
        if (found) {
            return found;
        }

    }

    return undefined;
}

function setInternalPadding(
    contentBlock: ContentBlockShape,
    paddingClassname: string,
): ContentBlockShape {
    if (contentBlock.content_block_type === ContentBlockType.AccordionItem) {
        return {
            ...contentBlock,
            theme: {
                ...contentBlock.theme,
                tailwindClasses: cnToArray([
                    ...contentBlock.theme?.tailwindClasses,
                    paddingClassname,
                ]),
            },
        };
    }

    return {
        ...contentBlock,
        contentBlocks: contentBlock.contentBlocks?.map((child) =>
            setInternalPadding(child, paddingClassname)
        ),
    };
}

function updatePaddingForAccordionItems(
    contentBlock: ContentBlockShape,
    paddingClassname: string,
): ContentBlockShape {
    if (contentBlock.content_block_type === ContentBlockType.Accordion) {
        return {
            ...contentBlock,
            theme: {
                ...contentBlock.theme,
                tailwindClasses: cnToArray([
                    ...contentBlock.theme?.tailwindClasses,
                    paddingClassname,
                ]),
            },
        };
    }

    return {
        ...contentBlock,
        contentBlocks: contentBlock.contentBlocks?.map((child) =>
            updatePaddingForAccordionItems(child, paddingClassname)
        ),
    };
}

export function AccordionSpacingMenu({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            setContentBlock(updatedBlock);
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );



    const firstItem = useMemo(() => {
        return findAccordionItem(contentBlock);
    }, [contentBlock]);

    if (!firstItem) return null;
    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Internal Padding 
                </label>
                <ButtonGroup
                    selectedValue={(
                        firstItem.theme?.tailwindClasses as string[]
                    )
                        ?.filter(
                            (className) =>
                                className.includes("px-") ||
                                className.includes("py-"),
                        )
                        .join(" ")}
                    values={[
                        { label: "S", value: "px-2 py-1" },
                        { label: "M", value: "px-4 py-2" },
                        { label: "L", value: "px-6 py-4" },
                    ]}
                    handleChange={(value) => {
                        update(
                            setInternalPadding(contentBlock, value as string),
                        );
                    }}
                />
            </div>
            <div className="flex flex-col gap-2 pb-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Border Radius
                </label>
                <ButtonGroup
                    selectedValue={(
                        firstItem.theme?.tailwindClasses as string[]
                    )
                        ?.filter((className) => className.includes("rounded"))
                        .join(" ")}
                    values={[
                        { label: "None", value: "rounded-0" },
                        { label: "S", value: "rounded-sm" },
                        { label: "M", value: "rounded-md" },
                        { label: "L", value: "rounded-lg" },
                    ]}
                    handleChange={(value) => {
                        update(updatePaddingForAccordionItems(contentBlock, value as string));
                    }}
                />
            </div>
        </div>
    );
}

export function AccordionColorMenu({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const save = useCallback(debounce(mutate, 2000), [contentBlock]);
    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            setContentBlock(updatedBlock);
            updatedBlock?.contentBlocks?.forEach((child) => {
                setContentBlock(child);
            });
            save(updatedBlock);
        }, 100),
        [contentBlock],
    );

    const firstItem = useMemo(() => {
        return contentBlock.contentBlocks?.[0]?.contentBlocks?.[0];
    }, [contentBlock]);

    if (!firstItem) return null;
    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    {contentBlock.content_block_type ===
                    ContentBlockType.Accordion
                        ? "Accordion"
                        : "Tab"}{" "}
                    Background Color
                </label>
                <ColorPickerPopover
                    value={
                        contentBlock?.contentBlocks?.[0]?.theme
                            ?.backgroundColor || "white"
                    }
                    onChange={(color) => {
                        update({
                            ...contentBlock,
                            contentBlocks: contentBlock.contentBlocks.map(
                                (block) => ({
                                    ...block,
                                    theme: {
                                        ...block.theme,
                                        backgroundColor: color,
                                    },
                                }),
                            ),
                        });
                    }}
                    onGradientChange={(gradient) => {
                        console.log(gradient);
                    }}
                />
                <ColorSelector
                    selectedColor={
                        contentBlock?.contentBlocks?.[0]?.theme
                            ?.backgroundColor || "white"
                    }
                    selectColor={(backgroundColor, color) => {
                        update({
                            ...contentBlock,
                            contentBlocks: contentBlock.contentBlocks.map(
                                (block) => ({
                                    ...block,
                                    theme: {
                                        ...block.theme,
                                        backgroundColor,
                                        backgroundColorId: color?.id,
                                    },
                                }),
                            ),
                        });
                    }}
                />
            </div>
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    {contentBlock.content_block_type ===
                    ContentBlockType.Accordion
                        ? "Accordion"
                        : "Tab"}{" "}
                    Border Color
                </label>
                <ColorSelector
                    selectedColor={
                        contentBlock?.contentBlocks?.[0]?.theme
                            ?.backgroundColor || "white"
                    }
                    selectColor={(borderColor, color) => {
                        update({
                            ...contentBlock,
                            contentBlocks: contentBlock.contentBlocks.map(
                                (block) => ({
                                    ...block,
                                    theme: {
                                        ...block.theme,
                                        borderColor: color,
                                    },
                                    contentBlocks: block.contentBlocks.map(
                                        (grandChild) => ({
                                            ...grandChild,
                                            theme: {
                                                ...grandChild.theme,
                                                borderColor,
                                            },
                                            borderColorId: color.id,
                                        }),
                                    ),
                                }),
                            ),
                        });
                    }}
                />
            </div>
        </div>
    );
}
