import { defaultTopDrawerTheme } from "@/styles/themes/TopDrawerTheme";
import { ContentBlockShape, ContentBlockType } from "../../models";
import { ContentBlockConfig } from "../ContentBlockConfigInterface";
import { buildTopBarCard } from "../content-block-layouts/DataDisplayCardLayout";

export const TopDrawerConfig: ContentBlockConfig = {
    typeKey: "Bottom Drawer",
    type: ContentBlockType["Bottom Drawer"],
    defaultTheme: defaultTopDrawerTheme,
    addableChildBlocks: [
        ContentBlockType.Box,
        ContentBlockType["Rich Text"],
        ContentBlockType.Button,
        ContentBlockType["Submission Count"],
        ContentBlockType.Section,
    ],
    availableBehaviors: [],
    onClickAdd(parentContentBlock) {
        const cardDeck = (
            parentContentBlock.contentBlocks as ContentBlockShape[]
        )?.find(
            (block) =>
                block.content_block_type === ContentBlockType["Card Deck"]
        );
        const block = buildTopBarCard();
        block.parent_content_block_id = cardDeck?.id || parentContentBlock.id;
        block.round_id = parentContentBlock.round_id;
        return block;
    },
};
