import React, { useCallback } from "react";
import { useHasUnsavedChanges } from "./useHasUnsavedChanges";
import {
    useFindContentBlockMethods,
    useGotContentBlock,
} from "../useContentBlockStore";
import { useAtom } from "jotai";
import {
    activeContentBlockAtom,
    useGetActiveContentBlock,
} from "./useGetActiveContentBlock";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { getActiveContentBlock } from "./getActiveContentBlock";
import { confirmDiscard } from "./confirmDiscard";
import { useModalQueryParams } from "../useModalQueryParams/useModalQueryParams";
import { setContent } from "@tiptap/core/dist/packages/core/src/commands";
import { useContentBlockState } from "@/Pages/DesignStudio/Design/useContentBlockState";

const modalTypes = [ContentBlockType.Modal, ContentBlockType["Inbox Message"]];

export function useSelectActiveContentBlock() {
    const { getContentBlock } = useFindContentBlockMethods();

    const hasModalParent = useCallback(
        (block: ContentBlockShape): ContentBlockShape | false => {
            if (modalTypes.includes(block?.content_block_type)) {
                return block;
            }

            while (block?.parent_content_block_id) {
                const parentBlock = getContentBlock(
                    block?.parent_content_block_id,
                );
                if (parentBlock === undefined) {
                    return false;
                }

                return hasModalParent(parentBlock);
            }
            return false;
        },
        [getContentBlock],
    );

    const hasUnsavedChanges = useHasUnsavedChanges();
    // const {} = usePromptStore
    const { setActiveContentBlock: updateActiveContentBlock } =
        useGetActiveContentBlock();
    const { setContentBlock, contentBlocks } = useContentBlockState();
    const { openModal, closeModal, isAnyModalOpen } = useModalQueryParams();
    const { gotContentBlock } = useGotContentBlock();

    const handleApiBlock = useCallback(
        async (id: string) => {
            const apiBlock = await getActiveContentBlock(id);
            return apiBlock;
        },
        [getContentBlock],
    );


    const setActiveContentBlock = useCallback(
        async (id: string) => {

            if(!id) {
                updateActiveContentBlock(false);
                return;
            }

            if (
                hasUnsavedChanges &&
                //For some reason, this is not working in the test environment.
                //we probably want to replace this with a modal, anyway
                !confirmDiscard()
            ) {
                return false;
            }

            let block = getContentBlock(id);
            if (block) {
                if (block.content_block_type === ContentBlockType.Typography) {
                    block = {
                        ...block,
                        content_block_type: ContentBlockType["Rich Text"],
                    };
                }
                updateActiveContentBlock(block);
                const modalParent = hasModalParent(block);
                if (modalParent) {
                    openModal({
                        modalComponent: "ContentBlockModal",
                        modalContentBlockId: modalParent.id,
                    });
                } else if (isAnyModalOpen) {
                    closeModal();
                }
                return;
            }

            let apiBlock = await handleApiBlock(id);
            if (apiBlock) {
                if (
                    apiBlock.content_block_type === ContentBlockType.Typography
                ) {
                    apiBlock = {
                        ...apiBlock,
                        content_block_type: ContentBlockType["Rich Text"],
                    };
                }
                console.log("apiBlock", apiBlock);
                updateActiveContentBlock(apiBlock);
                if (!contentBlocks[apiBlock.id]) {
                    console.log("setting content block", apiBlock, contentBlocks);
                    setContentBlock(apiBlock);
                }

                const modalParent = hasModalParent(apiBlock);
                if (modalParent) {
                    openModal({
                        modalComponent: "ContentBlockModal",
                        modalContentBlockId: modalParent.id,
                    });
                } else if (isAnyModalOpen) {
                    closeModal();
                }
                return;
            }
        },
        [
            hasUnsavedChanges,
            getContentBlock,
            updateActiveContentBlock,
            gotContentBlock,
            isAnyModalOpen,
        ],
    );

    const handler = useCallback(
        (event: React.MouseEvent | React.KeyboardEvent) => {
            event.stopPropagation();
            event.preventDefault();
            let id = "";
            if (event.currentTarget.hasAttribute("data-content-block-id")) {
                id = event.currentTarget.getAttribute("data-content-block-id");
            } else if (event.currentTarget.closest("[data-content-block-id]")) {
                id = event.currentTarget
                    .closest("[data-content-block-id]")
                    .getAttribute("data-content-block-id");
            }
            if (id) {
                setActiveContentBlock(id);
            }
        },
        [setActiveContentBlock],
    );

    return { handler, setActiveContentBlock };
}
