import React, { ReactNode, useEffect, useState, useMemo } from "react";
import {
    ChartContentBlock,
    Color,
    ContentBlock,
    ContentBlockShape,
    ContentBlockType,
    ReflectionJournalEntry,
    Prompt,
    TableData,
} from "@/models";
import { getDefaultTheme, ContentBlockComponents } from "@/model-configs";
import {
    defaultLinkTheme,
    defaultTypographyTheme,
    FormContentBlockDefaultValuesObject,
    FormValidationSchema,
    OthersSelectionsPopover,
    Cell,
    HeaderCell,
    Row,
    TBody,
    defaultTableTheme,
} from "@/styles";
import { useEditorActiveLineage, useVariableValueStore } from "@/hooks";
import { useAuthStore } from "@/hooks/store";
import { ContentBlockEvents } from "@/styles/themes/ContentBlockEvents";
import {
    ContentEditor,
    TextDisplay,
    toRichText,
} from "../../../../modules/text-editor";
import { ModelDataDisplay } from "@/styles/themes/ModelDataDisplayTheme";
import {
    useOthersSelections,
    useParticipantStore,
} from "@/Pages/Participant/SimulationDisplay/redux-state/hooks";
import { useInView } from "react-intersection-observer";
import { useSidebarState } from "@/hooks/store/useSidebarState";
import { useModalQueryParams } from "@/hooks/useModalQueryParams/useModalQueryParams";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import RichTextBlock from "@/Pages/DesignStudio/Display/rich-text/RichTextBlock";
//TODO:put this file in /modules/shared

function RenderManualTable({
    contentBlock,
}: {
    contentBlock: ContentBlock | ContentBlockShape;
    theme: typeof defaultTableTheme;
}) {
    return (
        <>
            <thead>
                <Row>
                    {!!contentBlock.table_data[0] &&
                        contentBlock.table_data[0].map((cell, i) => {
                            return (
                                <HeaderCell key={i}>
                                    <TextDisplay
                                        richText={cell}
                                        editedId={null}
                                        theme={undefined}
                                    />
                                </HeaderCell>
                            );
                        })}
                </Row>
            </thead>
            <TBody>
                {contentBlock?.table_data
                    ?.filter((row, rowIndex) => rowIndex !== 0)
                    .map((row, rowIndex) => (
                        <Row key={`${contentBlock.id}_row_${rowIndex}`}>
                            {row.map((cell, cellIndex) => {
                                return (
                                    <Cell
                                        key={`${contentBlock.id}_row_${rowIndex}_cell_${cellIndex}`}
                                    >
                                        <TextDisplay
                                            richText={cell}
                                            editedId={`${contentBlock.id}_row_${rowIndex}_cell_${cellIndex}`}
                                            theme={undefined}
                                        />
                                    </Cell>
                                );
                            })}
                        </Row>
                    ))}
            </TBody>
        </>
    );
}

type ContentBlockDisplayProps = ContentBlockEvents & {
    contentBlock: ContentBlock | ContentBlockShape;
    children?: ReactNode;
    isSelected: boolean;
    isEditable: boolean;
    childObject?: { [index: string]: string };
    activeId?: string;
    isInEditorContext?: boolean;
    editorSizes?: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
    colors?: { [index: string]: Color };
    tableData?: TableData;
    simulationTheme: any;
    activeChildIndex: number;
    // setActiveTab: (contentBlockId?: string) => void;
    formProps?: {
        questionFormObject?: FormContentBlockDefaultValuesObject;
        onFormSubmit?: (
            formObject: FormContentBlockDefaultValuesObject,
        ) => Promise<void>;
        validationSchema?: FormValidationSchema;
        isSubmitting?: boolean;
        isValid?: boolean;
    };
    childBlocks?: ContentBlockShape[];
    homeRoute: string;
    renderMap: { [index: string]: JSX.Element | JSX.Element[] };
    reflectionJournalEntries: ReflectionJournalEntry[];
};

function RenderBlock({
    contentBlock,
    onHover,
    isEditable,
    children,
    childObject,
    childBlocks,
    homeRoute,
    isSelected,
    isInEditorContext,
    simulationTheme,
    activeId,
    formProps,
    renderMap,
    reflectionJournalEntries,
    editorSizes,
}: ContentBlockDisplayProps) {
    // const { updateActiveContentBlock } = isInEditorContext
    //     ? {
    //           updateActiveContentBlock: () => {},
    //       }
    //     : {
    //           updateActiveContentBlock: () => {},
    //       };

    const { activeLineage } = isInEditorContext
        ? useEditorActiveLineage()
        : {
              activeLineage: null,
          };

    const [activeChildKeys, setActiveChildKeys] = useState<string[]>([]);

    function handleAccordionChange(
        contentBlock: ContentBlock | ContentBlockShape,
        activeIndices: number[] | number,
    ) {
        if (Array.isArray(activeIndices)) {
            const keys = [...contentBlock.contentBlocks]
                .filter((_, i) => activeIndices.indexOf(i) !== -1)
                .map((block) => block.id);

            setActiveChildKeys(keys);
        } else {
            const block = [...contentBlock.contentBlocks].find(
                (_, i) => activeIndices === i,
            );

            if (block) setActiveChildKeys([block.id]);
        }
    }

    useEffect(() => {
        if (childObject !== undefined && activeId) {
            // setActiveChildIndex(newActiveChildIndex);
            let newActiveChildKey = Object.keys(childObject).find(
                (childKey) =>
                    childKey === activeId ||
                    activeLineage[childKey] !== undefined,
            );
            setActiveChildKeys([newActiveChildKey]);
        }
    }, [activeId, childObject]);

    const {
        getMappedFormattedChartData,
        generateContentBlocksForModelTable,
        generateContentBlocksForLeaderBoard,
        getContentForSingleVariableValue,
    } = useVariableValueStore();

    const mappedFormattedChartData = useMemo(() => {
        if (
            Array.isArray(contentBlock?.chart_data) &&
            contentBlock?.chart_data.length
        ) {
            alert(contentBlock.id);
            return [contentBlock.chart_data];
        }
        if (
            !!contentBlock?.chart_data &&
            Object.keys(contentBlock?.chart_data)?.length
        ) {
            return contentBlock.chart_data;
        }

        if (
            contentBlock.content_block_type ===
            ContentBlockType["Chart Container"]
        )
            return getMappedFormattedChartData(childBlocks || []);
    }, [contentBlock]);

    const tableConfig = useMemo(() => {
        if (contentBlock.content_block_type === ContentBlockType["Table"]) {
            return generateContentBlocksForModelTable({
                ...contentBlock,
                contentBlocks: childBlocks,
            } as ContentBlock);
        }
        return undefined;
    }, [contentBlock]);

    const { othersSelectionsByContentBlockId, decisionLogSelections } =
        useOthersSelections();

    const { submissionCount, unreadMessageIds } = useParticipantStore();

    const { user, team } = useAuthStore();

    const getTheme = () => {
        let blockCopy = { ...contentBlock };

        return blockCopy &&
            blockCopy.theme &&
            Object.keys(blockCopy.theme).length
            ? blockCopy.theme
            : getDefaultTheme(blockCopy.content_block_type);
    };

    const { isWrappingElementPresent } = useSidebarState();

    const { queryParams, closeModal } =
        useModalQueryParams("ContentBlockModal");

    const richTextBlock = useMemo(() => {
        return toRichText(contentBlock);
    }, [contentBlock]);

    const variableValue = useMemo(() => {
        if (
            contentBlock.content_block_type !==
            ContentBlockType["Model Value Display"]
        )
            return null;

        return getContentForSingleVariableValue(contentBlock);
    }, [contentBlock, getContentForSingleVariableValue]);

    switch (contentBlock.content_block_type) {
        case ContentBlockType.Navbar:
            return (
                <ContentBlockComponents.NAVBAR
                    theme={getTheme()}
                    isEditable={isEditable}
                    contentBlockId={contentBlock.id}
                    homeLinkText={contentBlock.content || contentBlock.title}
                    homeRoute={homeRoute}
                    isSidebarOpen={isWrappingElementPresent}
                >
                    {children}
                </ContentBlockComponents.NAVBAR>
            );
        case ContentBlockType["Top Drawer"]:
            return (
                <ContentBlockComponents.TOP_DRAWER
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                    isSelected={isSelected}
                    isInEditorContext={isInEditorContext}
                >
                    {children}
                </ContentBlockComponents.TOP_DRAWER>
            );
        case ContentBlockType.Page:
            return (
                <ContentBlockComponents.PAGE
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                    isInEditorContext={isInEditorContext}
                >
                    <>{children}</>
                </ContentBlockComponents.PAGE>
            );
        case ContentBlockType.Text:
            return (
                <ContentBlockComponents.TEXT
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                >
                    {contentBlock.content}
                </ContentBlockComponents.TEXT>
            );

        case ContentBlockType.Button:
            return (
                <ContentBlockComponents.BUTTON
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                    isInEditorContext={isInEditorContext}
                    isSubmitting={formProps?.isSubmitting}
                    isInvalid={formProps?.isValid === false}
                >
                    {!!richTextBlock?.rich_text && (
                        <RichTextBlock
                            editable={isEditable}
                            content={richTextBlock?.rich_text}
                            contentBlock={contentBlock}
                        />
                    )}
                    {children}
                </ContentBlockComponents.BUTTON>
            );
        case ContentBlockType.Link:
            const theme = getTheme() as typeof defaultLinkTheme;
            return (
                <ContentBlockComponents.LINK
                    theme={theme}
                    linkTarget={contentBlock.route}
                    contentBlockId={contentBlock.id}
                    isSidebarOpen={isWrappingElementPresent}
                    isInEditorContext={isInEditorContext}
                >
                    <>
                        {theme?.iconPosition === "before" && children}
                        {!!richTextBlock?.rich_text && (
                        <RichTextBlock
                            editable={isEditable}
                            content={richTextBlock?.rich_text}
                            contentBlock={contentBlock}
                        />
                    )}
                        {theme?.iconPosition === "after" && children}
                    </>
                </ContentBlockComponents.LINK>
            );
        case ContentBlockType.Icon:
            return (
                <ContentBlockComponents.ICON
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                />
            );
        case ContentBlockType.Section:
            return (
                <ContentBlockComponents.SECTION
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                    isInEditorContext={isInEditorContext}
                >
                    {children || (
                        <>
                            {!!isInEditorContext &&
                                contentBlock.content_block_type}
                        </>
                    )}
                </ContentBlockComponents.SECTION>
            );
        case ContentBlockType.Image:
            return (
                <ContentBlockComponents.IMAGE
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    imageUrl={contentBlock.image_url}
                    isInEditorContext={isInEditorContext}
                >
                    {children}
                </ContentBlockComponents.IMAGE>
            );
        case ContentBlockType.Video:
            return (
                <ContentBlockComponents.VIDEO
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    videoUrls={
                        contentBlock.video_urls?.reduce(
                            (carry, url) => ({ ...carry, [url]: url }),
                            {},
                        ) || {}
                    }
                />
            );
        case ContentBlockType.Hero:
            return (
                <ContentBlockComponents.HERO
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    imageUrl={contentBlock.image_url}
                >
                    <>{children}</>
                </ContentBlockComponents.HERO>
            );
        case ContentBlockType.Hero2:
            return (
                <ContentBlockComponents.Hero2
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    imageUrl={contentBlock.image_url}
                    palette={
                        simulationTheme.palettes[
                            contentBlock.theme?.palette || "light"
                        ]
                    }
                >
                    <>{children}</>
                </ContentBlockComponents.Hero2>
            );
        case ContentBlockType.Hero3:
            return (
                <ContentBlockComponents.Hero3
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    imageUrl={contentBlock.image_url}
                    palette={
                        simulationTheme.palettes[
                            contentBlock.theme?.palette || "light"
                        ]
                    }
                >
                    <>{children}</>
                </ContentBlockComponents.Hero3>
            );
        case ContentBlockType.Tabs:
            return (
                <ContentBlockComponents.TABS
                    theme={getTheme()}
                    tabObject={childObject || {}}
                    contentBlockId={contentBlock.id}
                    activeId={activeId}
                    title={contentBlock.title}
                >
                    {childObject !== undefined &&
                        Object.keys(childObject).map((childKey, i) => {
                            return children[i];
                        })}
                </ContentBlockComponents.TABS>
            );
        case ContentBlockType.Box:
            return (
                <ContentBlockComponents.BOX
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                >
                    {children}
                </ContentBlockComponents.BOX>
            );
        case ContentBlockType.Accordion:
            return (
                <ContentBlockComponents.ACCORDION
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    setActiveItemIndices={(indices: number[]) =>
                        handleAccordionChange(contentBlock, indices)
                    }
                    activeAccordionKeys={activeChildKeys}
                    isInDesignContext={isInEditorContext}
                    isSelected={
                        activeId === contentBlock.id ||
                        (!!activeLineage && !!activeLineage[contentBlock.id])
                    }
                >
                    {childObject !== undefined &&
                        Object.keys(childObject).map(
                            (childKey, i) => children[i],
                        )}
                </ContentBlockComponents.ACCORDION>
            );
        case ContentBlockType.AccordionItem:
            return (
                <ContentBlockComponents.ACCORDIONS_ITEM
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    isDisabled={
                        activeLineage &&
                        activeLineage.hasOwnProperty(contentBlock.id)
                    }
                >
                    {childObject !== undefined &&
                        (Object.keys(childObject).map(
                            (childKey, i) => children[i],
                        ) as [ReactNode, ReactNode])}
                </ContentBlockComponents.ACCORDIONS_ITEM>
            );
        case ContentBlockType.AccordionHeader:
            return (
                <ContentBlockComponents.ACCORDIONS_HEADER
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    parentContentBlockId={contentBlock.parent_content_block_id}
                >
                    {childObject !== undefined &&
                        Object.keys(childObject).map(
                            (childKey, i) => children[i],
                        )}
                </ContentBlockComponents.ACCORDIONS_HEADER>
            );
        case ContentBlockType.AccordionContent:
            return (
                <ContentBlockComponents.ACCORDIONS_CONTENT
                    contentBlockId={contentBlock.id}
                    parentContentBlockId={contentBlock.parent_content_block_id}
                    theme={getTheme()}
                >
                    {childObject !== undefined &&
                        Object.keys(childObject).map(
                            (childKey, i) => children[i],
                        )}
                </ContentBlockComponents.ACCORDIONS_CONTENT>
            );

        case ContentBlockType["Card Deck"]:
            return (
                <ContentBlockComponents.CARD_DECK
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                >
                    {children}
                </ContentBlockComponents.CARD_DECK>
            );
        case ContentBlockType.Grid:
            return (
                <ContentBlockComponents.GRID
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                >
                    {childObject !== undefined &&
                        Object.keys(childObject).map(
                            (childKey, i) => children[i],
                        )}
                </ContentBlockComponents.GRID>
            );
        case ContentBlockType.Card:
            return (
                <ContentBlockComponents.CARD
                    contentBlockId={contentBlock.id}
                    theme={getTheme()}
                    imageUrl={contentBlock.image_url}
                    palette={
                        simulationTheme?.palettes[
                            contentBlock.theme?.palette || "light"
                        ]
                    }
                    childIsSelected={
                        isInEditorContext && !!activeLineage[contentBlock.id]
                    }
                    isInEditorContext={isInEditorContext}
                >
                    {childObject !== undefined &&
                        Object.keys(childObject).map(
                            (childKey, i) => children[i],
                        )}
                </ContentBlockComponents.CARD>
            );
        case ContentBlockType.Tile:
            return (
                //TODO: is there a utility for tile?
                <ContentBlockComponents.BOX
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                >
                    {children}
                </ContentBlockComponents.BOX>
            );
        case ContentBlockType.Typography:
            return !isSelected ? (
                <ContentBlockComponents.TYPOGRAPHY
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                >
                    {contentBlock.content}
                </ContentBlockComponents.TYPOGRAPHY>
            ) : (
                <div
                    data-editor-portal-id={contentBlock.id}
                    className={`${!isSelected && "hidden"} relative z-[2000]`}
                ></div>
            );
        case ContentBlockType.Leaderboard:
            return (
                <ContentBlockComponents.TABLE
                    contentBlockId={contentBlock.id}
                    tableConfig={generateContentBlocksForLeaderBoard(
                        contentBlock,
                    )}
                    theme={getTheme()}
                    palette={
                        simulationTheme?.palettes?.length &&
                        simulationTheme.palettes[
                            contentBlock.theme?.palette || "light"
                        ]
                    }
                />
            );
        case ContentBlockType.Table:
            if (contentBlock.table_data?.length) {
                return (
                    <>
                        <div
                            data-table-editor-portal-id={contentBlock.id}
                            className={`${!isSelected && "hidden"} relative z-[2000] w-full`}
                        ></div>
                        {!isSelected && (
                            <ContentBlockComponents.TABLE
                                contentBlockId={contentBlock.id}
                                tableConfig={contentBlock}
                                theme={getTheme()}
                                palette={
                                    simulationTheme?.palettes?.length &&
                                    simulationTheme.palettes[
                                        contentBlock.theme?.palette || "light"
                                    ]
                                }
                            >
                                <RenderManualTable
                                    theme={getTheme()}
                                    contentBlock={contentBlock}
                                />
                            </ContentBlockComponents.TABLE>
                        )}
                    </>
                );
            }
            return (
                <ContentBlockComponents.TABLE
                    contentBlockId={contentBlock.id}
                    tableConfig={tableConfig}
                    theme={getTheme()}
                    palette={
                        simulationTheme?.palettes?.length &&
                        simulationTheme.palettes[
                            contentBlock.theme?.palette || "light"
                        ]
                    }
                />
            );
        case ContentBlockType["Rich Text"]:
            return (
                <RichTextBlock
                    editable={isInEditorContext}
                    content={contentBlock.rich_text}
                    contentBlock={contentBlock}
                />
            );

        case ContentBlockType.Form:
            return !!formProps && !!formProps.questionFormObject ? (
                <>
                    <ContentBlockComponents.FORM
                        theme={getTheme()}
                        contentBlockId={contentBlock.id}
                        questionFormObject={formProps.questionFormObject}
                        onFormSubmit={formProps?.onFormSubmit || undefined}
                        validationRules={
                            contentBlock.dynamicValidationRules || []
                        }
                        isSubmitting={formProps?.isSubmitting}
                        isInEditorContext={isInEditorContext}
                    >
                        {children || contentBlock.content_block_type}
                    </ContentBlockComponents.FORM>
                </>
            ) : (
                <div className="flex w-full max-w-7xl animate-pulse flex-col gap-4">
                    <div className="h-12 w-full rounded-md bg-gray-100"></div>
                    <div className="h-12 w-full rounded-md bg-gray-100"></div>
                    <div className="h-12 w-full rounded-md bg-gray-100"></div>
                    <div className="h-12 w-full rounded-md bg-gray-100"></div>
                    <div className="h-12 w-full rounded-md bg-gray-100"></div>
                    <div className="h-12 w-full rounded-md bg-gray-100"></div>
                    <div className="h-8 w-12 rounded-md bg-gray-100"></div>
                </div>
            );
        case ContentBlockType.Question:
            return !!simulationTheme ? (
                <ContentBlockComponents.QUESTION
                    promptId={contentBlock.prompt?.id || ""}
                    prompt={(contentBlock.prompt as Prompt) || new Prompt({})}
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                    zIndex={isSelected ? 100 : 49 - contentBlock.weight}
                    palette={
                        simulationTheme.palettes[
                            contentBlock.theme?.palette || "light"
                        ]
                    }
                    optionRenderMap={renderMap}
                    isInDesignContext={isInEditorContext}
                >
                    <>
                        {!!contentBlock?.behaviors?.question
                            ?.ShowOthersResponses &&
                            !!othersSelectionsByContentBlockId &&
                            !!othersSelectionsByContentBlockId[
                                contentBlock.id
                            ] &&
                            !isInEditorContext &&
                            !!user &&
                            !!team && (
                                <OthersSelectionsPopover
                                    othersSelections={
                                        othersSelectionsByContentBlockId[
                                            contentBlock.id
                                        ]
                                    }
                                    userId={user?.id}
                                    teamId={team?.id}
                                />
                            )}
                    </>
                </ContentBlockComponents.QUESTION>
            ) : (
                <div
                    className="my-2 w-full p-4"
                    data-content-block-id={contentBlock.id}
                >
                    {isInEditorContext && "QUESTION"}
                </div>
            );
        case ContentBlockType["Chart Container"]:
            return (
                <>
                    <ContentBlockComponents.CHART_CONTAINER
                        theme={getTheme()}
                        contentBlockId={contentBlock.id}
                        contentBlocks={childBlocks as ChartContentBlock[]}
                        formattedChartValues={
                            mappedFormattedChartData as {
                                [index: string]: FormattedChartValue[];
                            }
                        }
                    />
                </>
            );

        case ContentBlockType["Model Value Display"]:
            return (
                <ModelDataDisplay
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                    variableValue={variableValue}
                />
            );

        case ContentBlockType["Bottom Drawer"]:
            return (
                <ContentBlockComponents.BOTTOM_DRAWER
                    theme={getTheme()}
                    contentBlockId={contentBlock.id}
                    isInEditorContext={isInEditorContext}
                    isOpen={true} //TODO: evaluate based on participant state, probably or something
                >
                    {children}
                </ContentBlockComponents.BOTTOM_DRAWER>
            );
        case ContentBlockType.Modal:
            return (
                <ContentBlockComponents.MODAL
                    title={contentBlock.title}
                    isOpen={
                        queryParams?.modalContentBlockId === contentBlock.id
                    }
                    theme={getTheme()}
                    isInEditorContext={isInEditorContext}
                    contentBlockId={contentBlock.id}
                    closeOverride={closeModal}
                    leftMenuWidth={editorSizes?.leftMenu}
                    rightMenuWidth={editorSizes?.rightMenu}
                    topMenuHeight={editorSizes?.toolbar}
                >
                    {children}
                </ContentBlockComponents.MODAL>
            );
        case ContentBlockType.Inbox:
            return (
                <ContentBlockComponents.INBOX
                    isOpen={
                        queryParams?.modalContentBlockId === contentBlock.id
                    }
                    theme={getTheme()}
                    isInEditorContext={isInEditorContext}
                    contentBlockId={contentBlock.id}
                    unreadMessageIds={unreadMessageIds}
                    hasActiveChild={
                        !!activeLineage && !!activeLineage[contentBlock.id]
                    }
                >
                    {children}
                </ContentBlockComponents.INBOX>
            );
        case ContentBlockType["Inbox Message"]:
            return (
                <ContentBlockComponents.INBOX_MESSAGE
                    title={contentBlock.title}
                    isOpen={
                        queryParams?.modalContentBlockId === contentBlock.id
                    }
                    theme={getTheme()}
                    isInEditorContext={isInEditorContext}
                    contentBlockId={contentBlock.id}
                    isRead={unreadMessageIds?.every(
                        (notificationId) => notificationId !== contentBlock.id,
                    )}
                    closeOverride={closeModal}
                >
                    {children}
                </ContentBlockComponents.INBOX_MESSAGE>
            );
        case ContentBlockType["Inbox Notification"]:
            return (
                <>
                    <ContentBlockComponents.INBOX_NOTIFICATION
                        title={contentBlock.title}
                        isOpen={
                            queryParams?.modalContentBlockId === contentBlock.id
                        }
                        theme={getTheme()}
                        isInEditorContext={isInEditorContext}
                        contentBlockId={contentBlock.id}
                        isRead={unreadMessageIds?.every(
                            (notificationId) =>
                                notificationId !==
                                contentBlock?.behaviors?.click
                                    ?.ShowModalContentBlock
                                    ?.modalContentBlockId,
                        )}
                    >
                        {children}
                    </ContentBlockComponents.INBOX_NOTIFICATION>
                </>
            );

        case ContentBlockType["Reflection journal"]:
            return (
                <ContentBlockComponents.REFLECTION_JOURNAL
                    theme={getTheme()}
                    isInEditorContext={isInEditorContext}
                    contentBlockId={contentBlock.id}
                    reflectionJournalEntries={reflectionJournalEntries}
                />
            );

        case ContentBlockType["Decision log"]:
            return (
                <ContentBlockComponents.DECISION_LOG
                    theme={getTheme()}
                    isInEditorContext={isInEditorContext}
                    contentBlockId={contentBlock.id}
                    selections={decisionLogSelections}
                    userId={user?.id}
                    teamId={team?.id}
                />
            );
        case ContentBlockType["Submission Count"]:
            return (
                <ContentBlockComponents.SUBMISSION_COUNT
                    theme={getTheme()}
                    submissionCount={
                        submissionCount && submissionCount[contentBlock.id]
                            ? submissionCount[contentBlock.id]
                            : {
                                  participant_count: 4,
                                  selection_count: 3,
                              }
                    }
                    isInEditorContext={isInEditorContext}
                    contentBlockId={contentBlock.id}
                >
                    <>{isSelected ? <ContentEditor /> : contentBlock.content}</>
                </ContentBlockComponents.SUBMISSION_COUNT>
            );
        default:
            const Component =
                ContentBlockComponents[contentBlock.content_block_type];

            //place holder for content blocks belonging to options
            if (contentBlock.content_block_type === ContentBlockType.option)
                return null;

            return Component ? (
                <Component
                    theme={getTheme()}
                    isInEditorContext={isInEditorContext}
                    contentBlockId={contentBlock.id}
                    title={contentBlock.title}
                    isSelected={
                        activeId === contentBlock.id ||
                        (!!activeLineage && !!activeLineage[contentBlock.id])
                    }
                >
                    {children}
                </Component>
            ) : (
                <div>{children}</div>
            );
    }
}

export const ContentBlockDisplay: React.FC<ContentBlockDisplayProps> = (
    props: ContentBlockDisplayProps,
) => {
    const { contentBlock } = props;

    const [isShown, setIsShown] = useState(false);

    const { ref } = useInView({
        onChange(_, intersectionObserverEntry) {
            if (
                contentBlock?.behaviors?.scroll?.DisplayOnScroll &&
                !isShown &&
                intersectionObserverEntry.intersectionRatio > 0
            ) {
                const { delay, animation } =
                    contentBlock.behaviors.scroll.DisplayOnScroll;

                //delay time is entered by designers in seconds
                const delayInMiliseconds = delay * 1000;

                if (animation !== "none") {
                    setIsShown(true);

                    // const contentBlockElement =

                    //
                } else {
                    const timer = setTimeout(() => {
                        console.log("timed");
                        setIsShown(() => true);
                        clearTimeout(timer);
                    }, delayInMiliseconds);
                }
            }
        },
    });

    return !!contentBlock?.behaviors?.scroll?.DisplayOnScroll ? (
        <>
            <div ref={ref} className="absolute"></div>

            {contentBlock?.behaviors?.scroll?.DisplayOnScroll?.animation ===
            "none" ? (
                isShown ? (
                    <RenderBlock {...props} />
                ) : (
                    <ContentBlockComponents.BOX
                        theme={{
                            ...defaultTypographyTheme,
                            p: [4],
                        }}
                    >
                        {
                            contentBlock.behaviors.scroll.DisplayOnScroll
                                ?.elementOnDelay
                        }
                    </ContentBlockComponents.BOX>
                )
            ) : isShown ? (
                <RenderBlock {...props} />
            ) : (
                <>has animation</>
            )}
        </>
    ) : (
        <RenderBlock {...props} />
    );
};
