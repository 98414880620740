import { StyledCardDeck } from "@/styles/themes/CardDeckTheme";
import { ReflectionJournalTheme } from "@/styles/themes/reflection-journal/ReflectionJournalTheme";
import { StyledRichText } from "@/styles/themes/RichTextTheme";
import { ContentBlockType } from "../models";
import {
    StyledText,
    StyledButton,
    StyledBox,
    StyledPage,
    StyledNavbar,
    StyledQuestion,
    StyledLink,
    StyledSection,
    StyledImage,
    StyledHero,
    StyledHero2,
    StyledHero3,
    StyledTabs,
    StyledAccordions,
    StyledGrid,
    StyledCard,
    StyledTypography,
    StyledAccordionItem,
    StyledAccordionHeader,
    StyledAccordionPanel,
    StyledTable,
    StyledVideo,
    StyledFormContentBlock,
    ChartContainer,
    LineSeries,
    StyledIcon,
    StyledBottomDrawer,
    ModalTheme,
    DecisionLogTheme,
    InboxTheme,
    InboxMessage,
    InboxNotification,
    SubmissionCountTheme,
    StyledTopDrawer,
    StyledCarousel,
} from "../styles";

export const ContentBlockComponents = {
    [ContentBlockType.Text]: StyledText,
    [ContentBlockType["Rich Text"]]: StyledRichText, //x
    [ContentBlockType.Question]: StyledQuestion,
    [ContentBlockType.Button]: StyledButton, //x, with behaviors
    [ContentBlockType.Link]: StyledLink, //x, with behaviors
    [ContentBlockType.Icon]: StyledIcon,
    [ContentBlockType.Box]: StyledBox, //x
    [ContentBlockType.Section]: StyledSection, //x
    [ContentBlockType.Page]: StyledPage, //x
    [ContentBlockType.Navbar]: StyledNavbar, //x (maybe not, because of positioning of the navbar)
    [ContentBlockType.Image]: StyledImage, //x
    [ContentBlockType.Video]: StyledVideo, //x
    [ContentBlockType.Hero]: StyledHero, //x
    Hero2: StyledHero2, //x
    Hero3: StyledHero3, //x
    [ContentBlockType.Tabs]: StyledTabs,//?
    [ContentBlockType.Accordion]: StyledAccordions, //?
    [ContentBlockType.AccordionItem]: StyledAccordionItem, //?
    [ContentBlockType.AccordionHeader]: StyledAccordionHeader, //?
    [ContentBlockType.AccordionContent]: StyledAccordionPanel, //?
    [ContentBlockType.Grid]: StyledGrid, //x
    [ContentBlockType["Card Deck"]]: StyledCardDeck, //x
    [ContentBlockType.Card]: StyledCard, //x
    [ContentBlockType.Typography]: StyledTypography, //x
    [ContentBlockType.Leaderboard]: StyledTable,//?
    [ContentBlockType.Table]: StyledTable,//?
    [ContentBlockType.Form]: StyledFormContentBlock,
    [ContentBlockType.Carousel]: StyledCarousel,
    //Charts
    [ContentBlockType["Chart Container"]]: ChartContainer,
    [ContentBlockType["Line Series"]]: LineSeries,
    [ContentBlockType["Bottom Drawer"]]: StyledBottomDrawer,
    [ContentBlockType["Top Drawer"]]: StyledTopDrawer,

    [ContentBlockType.Modal]: ModalTheme,
    [ContentBlockType["Reflection journal"]]: ReflectionJournalTheme,
    [ContentBlockType["Decision log"]]: DecisionLogTheme,
    [ContentBlockType["Submission Count"]]: SubmissionCountTheme,
    [ContentBlockType.Inbox]: InboxTheme,
    [ContentBlockType["Inbox Message"]]: InboxMessage,
    [ContentBlockType["Inbox Notification"]]: InboxNotification,
};
