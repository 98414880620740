import {
    TypographyProps,
    SpaceProps,
    ColorProps,
    BorderProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
    GridProps,
    BackgroundProps,
    BoxShadowProps,
} from "styled-system";
import {
    GlobalTheme,
    fontWeightObject,
    letterSpacingObject,
    borderWidthObject,
    lineHeightObject,
} from "./GlobalTheme";
import { TypographyType, TypographyTypeValues } from "./SimulationTheme";
import { SimColors } from "@/models";
import { ContentBlockConfig } from "@/model-configs";
import * as icons from "@heroicons/react/24/outline";

export type ThemeObject = TypographyProps &
    ColorProps &
    SpaceProps &
    BorderProps &
    LayoutProps &
    FlexboxProps &
    PositionProps &
    GridProps &
    BackgroundProps &
    IHoverColorProps &
    IActiveColorProps &
    IDisabledColorProps &
    IAdditionalProps &
    IColorProps &
    BoxShadowProps & { [index: string]: any } & {
        animationClasses?: string[];
    };

// export interface ITypographyProps {
//     fontFamily?: string;
//     fontSize?: number;
//     fontWeight?: string | number;
//     letterSpacing?: string | number;
//     textAlign?: TextAlign;
//     fontStyle?: FontStyle;
// }

interface IColorProps {
    color?: string;
    backgroundColor?: string;
    opacity?: number;
}

// export interface ISpaceProps {
//     pt?: string | number;
//     pr?: string | number;
//     pb?: string | number;
//     pl?: string | number;
//     py?: string | number;
//     px?: string | number;
//     mt?: string | number;
//     mr?: string | number;
//     mb?: string | number;
//     ml?: string | number;
//     my?: string | number;
//     mx?: string | number;
// }

// export interface IBorderProps {
//     border?: string | number; // theme: borders
//     borderWidth?: string | number;
//     borderStyle?: string; // type; theme: borderStyles
//     borderColor?: string;
//     borderRadius?: string | number; // theme: radii
// }

// export interface ILayoutProps {
//     width?: string | number | Array<any>; // theme: sizes
//     height?: string | number | Array<any>;
//     minWidth?: string | number | Array<any>;
//     minHeight?: string | number | Array<any>;
//     maxWidth?: string | number | Array<any>;
//     maxHeight?: string | number | Array<any>;
//     size?: string | number | Array<any>;
//     display?: Display;
//     // verticalAlign?: string; // type/string
//     // overflow?: string; // type/string
//     // overflowX?: string; // type/string
//     // overflowY?: string; // type/string
// }

// export interface IPositionProps {
//     position?: string;
//     zIndex?: number;
//     top?: number;
//     right?: number;
//     bottom?: number;
//     left?: number;
// }

interface IHoverColorProps {
    hoverColor?: string;
    hoverBackgroundColor?: string;
    hoverBorderColor?: string;
}

interface IActiveColorProps {
    activeBackgroundColor?: string;
    activeColor?: string;
    activeBorderColor?: string;
}

interface IDisabledColorProps {
    disabledBorderColor?: string;
    disabledBackgroundColor?: string;
    disabledColor?: string;
}

interface IAdditionalProps {
    textTransform?: TextTransform;
    cursor?: string;
    widthBreakpoint1?: any;
    widthBreakpoint2?: any;
    widthBreakpoint3?: any;
    widthBreakpoint4?: any;
    typographyType?: TypographyTypeValues;
    palette?: keyof SimColors;
}

// export interface IFlexboxProps {
//     alignItems?: AlignItems;
//     alignContent?: AlignContent;
//     justifyItems?: JustifyItems;
//     justifyContent?: JustifyContent;
//     flexWrap?: FlexWrap;
//     flexDirection?: FlexDirection;
// }

export enum TextAlign {
    "center" = "center",
    "left" = "left",
    "right" = "right",
    "justify" = "justify",
}

enum FontStyle {
    "normal" = "normal",
    "italic" = "italic",
}

enum TextTransform {
    "none" = "none",
    "uppercase" = "uppercase",
    "lowercase" = "lowercase",
    "capitalize" = "capitalize",
}

export enum Display {
    "block" = "block",
    "flex" = "flex",
    "grid" = "grid",
    "inline-flex" = "inline-flex",
}

export enum FlexWrap {
    "wrap" = "wrap",
    "nowrap" = "nowrap",
}

export enum FlexDirection {
    "row" = "row",
    "column" = "column",
}

export enum AlignItems {
    "normal" = "normal",
    "flex-start" = "flex-start",
    "flex-end" = "flex-end",
    "center" = "center",
    "baseline" = "baseline",
    "stretch" = "stretch",
}

export enum AlignContent {
    "normal" = "normal",
    "flex-start" = "flex-start",
    "flex-end" = "flex-end",
    "center" = "center",
    "baseline" = "baseline",
    "stretch" = "stretch",
    "space-between" = "space-between",
    "space-around" = "space-around",
    "space-evenly" = "space-evenly",
}

export enum JustifyItems {
    "normal" = "normal",
    "flex-start" = "flex-start",
    "flex-end" = "flex-end",
    "center" = "center",
    "baseline" = "baseline",
    "stretch" = "stretch",
    "left" = "left",
    "right" = "right",
}

export enum JustifyContent {
    "normal" = "normal",
    "flex-start" = "flex-start",
    "flex-end" = "flex-end",
    "center" = "center",
    "left" = "left",
    "right" = "right",
    "space-between" = "space-between",
    "space-around" = "space-around",
    "space-evenly" = "space-evenly",
}

export enum BackgroundSize {
    "cover" = "cover",
    "contain" = "contain",
}

// export enum BackgroundPosition {
//     "cover" = "cover",
//     "contain" = "contain",
// }

export interface ResponsiveOption {
    type: "string" | "fraction";
    label: string;
    value: string | number;
    num?: number;
    den?: number;
}

export const WidthOptions: ResponsiveOption[] = [
    { type: "string", label: "auto", value: "auto" },
    { type: "fraction", label: "100%", value: "100%", num: 1, den: 1 },
    { type: "fraction", label: "80% (4/5)", value: 0.8, num: 4, den: 5 },
    { type: "fraction", label: "75% (3/4)", value: 0.75, num: 3, den: 4 },
    { type: "fraction", label: "67% (2/3)", value: 0.66, num: 2, den: 3 },
    { type: "fraction", label: "60% (3/5)", value: 0.6, num: 3, den: 5 },
    { type: "fraction", label: "50% (1/2)", value: 0.5, num: 1, den: 2 },
    { type: "fraction", label: "40% (2/5)", value: 0.4, num: 2, den: 5 },
    { type: "fraction", label: "33% (1/3)", value: 0.33, num: 1, den: 3 },
    { type: "fraction", label: "25% (1/4)", value: 0.25, num: 1, den: 4 },
    { type: "fraction", label: "20% (1/5)", value: 0.2, num: 1, den: 5 },
];

export const widthToTailwindFraction = {
    "100%": "full",
    0.8: "4/5",
    0.75: "3/4",
    0.66: "2/3",
    0.6: "3/5",
    0.5: "1/2",
    0.4: "2/5",
    0.33: "1/3",
    0.25: "1/4",
    0.2: "1/5",
};

const groupedThemeProperties = {
    pt: "padding",
    pr: "padding",
    pb: "padding",
    pl: "padding",
    py: "padding",
    px: "padding",
    mt: "margin",
    mr: "margin",
    mb: "margin",
    ml: "margin",
    my: "margin",
    mx: "margin",
};

export const configureThemeObject = (
    themeObject: ThemeObject,
    config: ContentBlockConfig,
) => {
    const { variantOptions: variants } = config;
    let configuredObject = {};
    Object.keys(themeObject).forEach((key) => {
        //first, see if there's a matching variant theme object
        const variantOptions = variants?.find(
            (variantOption) => variantOption.themeObjectKey === key,
        );

        if (variantOptions) {
            configuredObject = {
                ...configuredObject,
                ...{
                    [key]: {
                        value: themeObject[key],
                        ...{
                            variantOptions,
                        },
                    },
                },
            };
        } else if (
            groupedThemeProperties[key] !== undefined &&
            (key !== "Typography" || config.forceTypographyStyles)
        ) {
            let groupedPropertyObject =
                configuredObject[groupedThemeProperties[key]] || {};
            configuredObject = {
                ...configuredObject,
                [groupedThemeProperties[key]]: {
                    ...groupedPropertyObject,
                    [key]: themeObject[key],
                },
            };
        } else if (key !== "margin" && key !== "padding") {
            configuredObject[key] = themeObject[key];
        }

        if (config.forceTypographyStyles) {
            configuredObject["fontSize"] =
                themeObject["fontSize"] || GlobalTheme.fontSizes[3];
            configuredObject["fontWeight"] =
                themeObject["fontWeight"] || GlobalTheme.fontWeights[4];
            configuredObject["lineHeight"] = themeObject["lineHeight"] || "1.5";
        }
    });
    return configuredObject;
};

export enum ConfigComponentType {
    "FormSelectObject" = "FormSelectObject",
    "FormSelectArrayIndex" = "FormSelectArrayIndex",
    "FormInputRadioArrayElement" = "FormInputRadioArrayElement",
    "FormInputToggleArrayElement" = "FormInputToggleArrayElement",
    "FormInputColorPicker" = "FormInputColorPicker",
    "FormInputRangeSlider" = "FormInputRangeSlider",
    "FormResponsiveProperty" = "FormResponsiveProperty",
    "FormSelectEnumType" = "FormSelectEnumType",
}

interface SliderData {
    step: string | number;
    min: string | number;
    max: string | number;
    isStacked?: boolean;
}

export interface ThemeProperty {
    configComponentType: ConfigComponentType;
    label: string;
    themeObjectKey: keyof ThemeObject;
    optionArray?: any[];
    optionObject?: { [index: string]: any };
    options?: { [index: string]: any };
    displayFunction?: (value: any) => string;
    sliderData?: SliderData;
    isGrouped?: boolean;
    groupObject?: { [index in keyof Partial<ThemeObject>]: string };
    responsiveOptions?: ResponsiveOption[];
}

export const themePropertyConfig: {
    [index in keyof Partial<ThemeObject>]: ThemeProperty;
} = {
    fontFamily: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Font Family",
        themeObjectKey: "fontFamily",
        optionObject: GlobalTheme.fonts,
    },
    fontSize: {
        configComponentType: ConfigComponentType.FormSelectArrayIndex,
        label: "Font Size",
        themeObjectKey: "fontSize",
        optionArray: GlobalTheme.fontSizes,
        displayFunction: (size: string | number) => `${size}px`,
    },
    fontWeight: {
        configComponentType: ConfigComponentType.FormSelectArrayIndex,
        label: "Font Weight",
        themeObjectKey: "fontWeight",
        optionArray: Object.keys(fontWeightObject),
    },
    letterSpacing: {
        configComponentType: ConfigComponentType.FormSelectArrayIndex,
        label: "Letter Spacing",
        themeObjectKey: "letterSpacing",
        optionArray: Object.keys(letterSpacingObject),
    },
    lineHeight: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Line Height",
        themeObjectKey: "lineHeight",
        optionObject: Object.values(lineHeightObject).reduce(
            (carry, value) => ({ ...carry, [value]: value }),
            {},
        ),
    },
    textAlign: {
        configComponentType: ConfigComponentType.FormInputRadioArrayElement,
        label: "Text Alignment",
        themeObjectKey: "textAlign",
        optionArray: Object.values(TextAlign),
    },
    fontStyle: {
        configComponentType: ConfigComponentType.FormInputToggleArrayElement,
        label: "Font Style",
        themeObjectKey: "fontStyle",
        optionArray: Object.values(FontStyle),
    },
    color: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Color",
        themeObjectKey: "color",
    },
    axisColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Axis Color",
        themeObjectKey: "axisColor",
    },
    gridColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Grid Color",
        themeObjectKey: "gridColor",
    },
    toolTipBackground: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Tooltip Background",
        themeObjectKey: "toolTipBackground",
    },
    backgroundColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Background Color",
        themeObjectKey: "backgroundColor",
    },
    // opacity: {
    //     configComponentType: ConfigComponentType.FormInputRangeSlider,
    //     label: "Opacity",
    //     themeObjectKey: "opacity",
    //     sliderData: {
    //         step: "0.05",
    //         min: "0",
    //         max: "1",
    //         isStacked: false,
    //     },
    // },
    padding: {
        configComponentType: ConfigComponentType.FormInputRangeSlider,
        label: "Padding",
        themeObjectKey: "padding",
        sliderData: {
            step: "1",
            min: "0",
            max: GlobalTheme.space.length - 1,
            isStacked: true,
        },
        isGrouped: true,
        groupObject: {
            pt: "Top",
            pb: "Bottom",
            pr: "Right",
            pl: "Left",
            py: "Vertical",
            px: "Horizontal",
        },
    },
    margin: {
        configComponentType: ConfigComponentType.FormInputRangeSlider,
        label: "Margin",
        themeObjectKey: "margin",
        sliderData: {
            step: "1",
            min: "0",
            max: GlobalTheme.space.length - 1,
            isStacked: true,
        },
        isGrouped: true,
        groupObject: {
            mt: "Top",
            mb: "Bottom",
            mr: "Right",
            ml: "Left",
            my: "Vertical",
            mx: "Horizontal",
        },
    },
    textTransform: {
        configComponentType: ConfigComponentType.FormInputRadioArrayElement,
        label: "Text Transform",
        themeObjectKey: "textTransform",
        optionArray: Object.values(TextTransform),
    },
    borderColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Border Color",
        themeObjectKey: "borderColor",
    },
    hoverColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Hover Color",
        themeObjectKey: "hoverColor",
    },
    hoverBackgroundColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Hover Background Color",
        themeObjectKey: "hoverBackgroundColor",
    },
    hoverBorderColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Hover Border Color",
        themeObjectKey: "hoverBorderColor",
    },
    borderWidth: {
        configComponentType: ConfigComponentType.FormSelectArrayIndex,
        label: "Border Width",
        themeObjectKey: "borderWidth",
        optionArray: Object.keys(borderWidthObject),
    },
    borderRadius: {
        configComponentType: ConfigComponentType.FormSelectArrayIndex,
        label: "Border Radius",
        themeObjectKey: "borderRadius",
        optionArray: GlobalTheme.radii,
    },
    display: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Display",
        themeObjectKey: "display",
        optionObject: Display,
    },
    alignItems: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Align Items",
        themeObjectKey: "alignItems",
        optionObject: AlignItems,
    },
    alignContent: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Align Content",
        themeObjectKey: "alignContent",
        optionObject: AlignContent,
    },
    justifyItems: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Justify Items",
        themeObjectKey: "justifyItems",
        optionObject: JustifyItems,
    },
    justifyContent: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Justify Content",
        themeObjectKey: "justifyContent",
        optionObject: JustifyContent,
    },
    flexWrap: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Flex Wrap",
        themeObjectKey: "flexWrap",
        optionObject: FlexWrap,
    },
    flexDirection: {
        configComponentType: ConfigComponentType.FormSelectObject,
        label: "Flex Direction",
        themeObjectKey: "flexDirection",
        optionObject: FlexDirection,
    },
    width: {
        configComponentType: ConfigComponentType.FormResponsiveProperty,
        label: "Width",
        themeObjectKey: "width",
        responsiveOptions: WidthOptions,
    },
    widthBreakpoint1: {
        configComponentType: ConfigComponentType.FormResponsiveProperty,
        label: "Width (first breakpoint)",
        themeObjectKey: "widthBreakpoint1",
        responsiveOptions: WidthOptions,
    },
    widthBreakpoint2: {
        configComponentType: ConfigComponentType.FormResponsiveProperty,
        label: "Width (second breakpoint)",
        themeObjectKey: "widthBreakpoint2",
        responsiveOptions: WidthOptions,
    },
    widthBreakpoint3: {
        configComponentType: ConfigComponentType.FormResponsiveProperty,
        label: "Width (third breakpoint)",
        themeObjectKey: "widthBreakpoint3",
        responsiveOptions: WidthOptions,
    },
    widthBreakpoint4: {
        configComponentType: ConfigComponentType.FormResponsiveProperty,
        label: "Width (fourth breakpoint)",
        themeObjectKey: "widthBreakpoint4",
        responsiveOptions: WidthOptions,
    },
    typographyType: {
        configComponentType: ConfigComponentType.FormSelectEnumType,
        label: "Typography Style",
        themeObjectKey: "typographyType",
        optionObject: TypographyType,
    },
    palette: {
        configComponentType: ConfigComponentType.FormSelectEnumType,
        label: "Color Theme",
        themeObjectKey: "palette",
        optionObject: {
            Light: "light",
            Dark: "dark",
        },
    },

    //button related props. Consider refactor
    activeBackgroundColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Active Background Color",
        themeObjectKey: "activeBackgroundColor",
    },
    activeColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Active Text Color",
        themeObjectKey: "activeColor",
    },
    activeBorderColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Active Border Color",
        themeObjectKey: "activeBorderColor",
    },

    disabledBorderColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Disabled Border Color",
        themeObjectKey: "disabledBorderColor",
    },
    disabledBackgroundColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Disabled Background Color",
        themeObjectKey: "disabledBackgroundColor",
    },
    disabledColor: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Disabled Color",
        themeObjectKey: "disabledColor",
    },
    fill: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Fill Color",
        themeObjectKey: "fill",
    },
    stroke: {
        configComponentType: ConfigComponentType.FormInputColorPicker,
        label: "Stroke Color",
        themeObjectKey: "stroke",
    },
    strokeWidth: {
        configComponentType: ConfigComponentType.FormInputRangeSlider,
        label: "Stroke Width",
        themeObjectKey: "strokeWidth",
    },
    interpolation: {
        configComponentType: ConfigComponentType.FormSelectEnumType,
        label: "Interpolation",
        themeObjectKey: "interpolation",
        optionObject: {
            basis: "basis",
            cardinal: "cardinal",
            catmullRom: "catmullRom",
            linear: "linear",
            monotoneX: "monotoneX",
            monotoneY: "monotoneY",
            natural: "natural",
            step: "step",
            stepAfter: "stepAfter",
            stepBefore: "stepBefore",
        },
    },
    iconPosition: {
        configComponentType: ConfigComponentType.FormSelectEnumType,
        label: "Icon Position",
        themeObjectKey: "iconPosition",
        optionObject: {
            "Before Text": "before",
            "After Text": "after",
        },
    },
    icon: {
        configComponentType: ConfigComponentType.FormSelectEnumType,
        label: "Icon",
        themeObjectKey: "icon",
        optionObject: Object.keys(icons).reduce((carry, icon) => {
            return { ...carry, [icon]: icon };
        }, []),
    },
    size: {
        configComponentType: ConfigComponentType.FormSelectEnumType,
        label: "Size",
        themeObjectKey: "size",
        optionObject: {
            "x-small": 3,
            small: 4,
            medium: 6,
            large: 12,
            "x-large": 16,
        },
    },
    // height and other layout props
};
