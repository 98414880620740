import { ToastProps } from "@/components/toasts/StyledToast";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";

const nofiticationAtom = atom<ToastProps | false>(false);
nofiticationAtom.debugLabel = "notification atom";
export function useNotificationStore() {
    const [notification, setNotification] = useAtom(nofiticationAtom);
    
    //clear out the notification after it's been on screen for the duration
    useEffect(() => {
        if(notification) {
            setTimeout(() => {
                setNotification(false);
            }, notification.autoClose + 1000);
        }
    }, [notification]);

    return {
        notification,
        setNotification,
    };
}
