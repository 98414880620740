import { useCallback } from "react";
import {
    CurrencyType,
    ModelVariableScope,
    ModelVariableUnit,
    Simulation,
    SimulationShape,
} from "@/models";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import { simulationsQueried } from "@/redux-state/simulationSlice";
import { atom, useAtom, useAtomValue } from "jotai";
import { variableValueFormatter } from "@/util";

const simulationAtom = atom<
    (Simulation | SimulationShape | App.Data.SimulationData) & { is_course: boolean }
>(undefined as SimulationShape & { is_course: boolean });

export const useSelectedSimulation = () => {
    const [selectedSimulation, gotSimulation] = useAtom<
        (Simulation | SimulationShape | App.Data.SimulationData) & { is_course: boolean }
    >(simulationAtom);

    return {
        selectedSimulation,
        gotSimulation,
    };
};

const currencyAtom = atom<CurrencyType | undefined>((get) => {
    const simulation = get(simulationAtom);
    return simulation?.config?.currency;
});

const useCurrency = () => {
    const [currency] = useAtom(currencyAtom);
    return currency;
};

const defaultModelVariableScopeAtom = atom<ModelVariableScope | undefined>(
    (get) => {
        const simulation = get(simulationAtom);
        return (
            simulation?.config?.defaultModelVariableScope ??
            ModelVariableScope.Team
        );
    },
);

export const useDefaultModelVariableScope = () => {
    const defaultModelVariableScope = useAtomValue(
        defaultModelVariableScopeAtom,
    );
    return defaultModelVariableScope;
};

export type VariableValueFormatFunction = (
    unitType: ModelVariableUnit,
    value: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => string;

export const useFormatVariableValue: () => VariableValueFormatFunction = () => {
    const currency = useCurrency();

    const formatVariableValue: VariableValueFormatFunction = useCallback(
        (
            unitType: ModelVariableUnit,
            value: number,
            isInteger?: boolean,
            abbrFunc?: (value: number, decimalPlaces: number) => string,
        ) =>
            variableValueFormatter(
                currency || CurrencyType.USD,
                unitType,
                value,
                isInteger,
                abbrFunc,
            ),
        [currency],
    );

    return formatVariableValue;
};

export const useAllSimulations = () => {
    const dispatch = useAppDispatch();

    const _simulations = useAppSelector(
        (state) =>
            state?.simulationStore?.simulations &&
            Object.values(state.simulationStore.simulations).sort(
                (a, b) => a.weight - b.weight,
            ),
    );

    const _gotSimulations = useCallback(
        (simulations: Simulation[]) =>
            dispatch(simulationsQueried(simulations)),
        [dispatch],
    );

    return {
        simulations: _simulations,
        gotSimulations: _gotSimulations,
    };
};
