import { ContentBlock, ContentBlockShape, ContentBlockType } from "../models";

const contentBlockHasPrompt = (
    contentBlock: ContentBlock | ContentBlockShape
) => {
    return (
        contentBlock.content_block_type === ContentBlockType.Question &&
        contentBlock.prompt &&
        Object.keys(contentBlock.prompt) &&
        Object.keys(contentBlock.prompt).length > 0
    );
};

export const contentBlockHasChildren = (
    contentBlock: ContentBlock | ContentBlockShape
) => {
    return (
        contentBlock?.contentBlocks !== undefined &&
        contentBlock?.contentBlocks?.length > 0
    );
};

export const getPromptsFromContentBlocks = (
    contentBlocks: (ContentBlock | ContentBlockShape)[]
) => {
    return contentBlocks?.length
        ? contentBlocks
              .filter((contentBlock) => contentBlockHasPrompt(contentBlock))
              .map((contentBlock) => contentBlock.prompt)
        : [];
};
