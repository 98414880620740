import { ContentBlockShape } from "@/models";
import { debounce } from "lodash";
import React, { useCallback } from "react";
import { useSaveContentBlock } from "../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../useContentBlockState";
import { ColorPickerPopover } from "@/components/color-picker";
import { useGotContentBlock } from "@/hooks";
import { useUpdateActiveContentBlock } from "@/hooks/activeContentBlock";

type Props = {
    contentBlock: ContentBlockShape;
};

export default function ColorForm({ contentBlock }: Props) {
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();
    const { gotContentBlock } = useGotContentBlock();
    const { updateActiveContentBlock } = useUpdateActiveContentBlock();

    const save = useCallback(debounce(mutate, 1000), [contentBlock]);
    const update = useCallback(
        debounce((contentBlock: ContentBlockShape) => {
            setContentBlock(contentBlock);
            updateActiveContentBlock(contentBlock);
            gotContentBlock(contentBlock);
            save(contentBlock);
        }, 100),
        [contentBlock],
    );
    const handleChange = useCallback((newBlock: ContentBlockShape) => {
        update(newBlock);
    }, []);

    const colorClassnames = (contentBlock.theme?.tailwindClasses || [])
        .filter(
            (className) =>
                className.includes("gradient") ||
                className.includes("to-") ||
                className.includes("from-"),
        )
        .join(" ");

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                    <label>Background Color</label>
                    <ColorPickerPopover
                        value={
                            colorClassnames?.length > 0
                                ? colorClassnames
                                : contentBlock.theme.backgroundColor
                        }
                        onChange={(color) => {
                            handleChange({
                                ...contentBlock,
                                theme: {
                                    ...contentBlock.theme,
                                    backgroundColor: color,
                                    "to-color": color,
                                    "from-color": color,
                                    tailwindClasses: [
                                        ...(
                                            contentBlock.theme
                                                ?.tailwindClasses || []
                                        ).filter(
                                            (className) =>
                                                !className.includes("to-") &&
                                                !className.includes("from-") &&
                                                !className.includes("via-") &&
                                                !className.includes("bg-") &&
                                                !className.includes("gradient"),
                                        ),
                                        "bg-[var(--bg-color)]",
                                    ],
                                },
                            });
                        }}
                        gradientColors={[
                            contentBlock.theme?.["from-color"],
                            contentBlock.theme?.["via-color"],
                            contentBlock.theme?.["to-color"],
                        ].filter(Boolean)}
                        onGradientChange={(gradient) => {
                            const tailwindClasses = [
                                ...(
                                    contentBlock.theme?.tailwindClasses || []
                                ).filter(
                                    (className) =>
                                        !className.includes("to-") &&
                                        !className.includes("from-") &&
                                        !className.includes("via-"),
                                ),
                                `bg-gradient-to-${gradient.angle || "r"}`,
                                `from-[var(--from-color)]`,
                                `to-[var(--to-color)]`,
                                `${
                                    gradient.designColors.length > 2
                                        ? `via-[var(--via-color)]`
                                        : ""
                                }`,
                            ].filter(Boolean);

                            const theme = {
                                ...contentBlock.theme,
                                "from-color":
                                    gradient.designColors[0].css_color,
                                "to-color":
                                    gradient.designColors[
                                        gradient.designColors.length - 1
                                    ].css_color,
                                "via-color":
                                    gradient.designColors.length > 2
                                        ? gradient.designColors[1].css_color
                                        : null,
                                tailwindClasses,
                            };

                            handleChange({
                                ...contentBlock,
                                theme,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
