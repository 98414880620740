import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, Simulation, SimulationShape } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

export type CreateContentBlockBody =
    (typeof LaravelRequestBodyShapes)["creator.design.content-blocks.store"];

export async function createContentBlock({
    body,
    selectedSimulation,å
}: {
    body: CreateContentBlockBody;
    selectedSimulation: Partial<Simulation | SimulationShape>;
}) {
    const { data } = await sapienAxios.post<
        { contentBlock: ContentBlockShape; activeContentBlockId: string },
        "creator.design.content-blocks.store"
    >("creator.design.content-blocks.store", body, {
        simulationSlug: selectedSimulation.slug,
        roundId: body.round_id,
    });

    const { contentBlock, activeContentBlockId } = data;
    return { contentBlock, activeContentBlockId };
}
